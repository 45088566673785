import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";
import { FormGroup, Input, InputGroup } from "reactstrap";

class GetName extends React.Component {
  constructor(props) {
    super();
    this.state = {
      name: props.current_photoshoot.clientName,
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    return (
      <Row className="justify-content-center">
        <Col lg="4" md="8" sm="12">
          <h5 style={{ marginBottom: "20px", fontWeight: 600 }}>
            What is the name of the client ?
          </h5>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Enter the name of the client"
                type="name"
                onChange={this.onChange}
                value={this.state.name}
                id="name"
                style={{ width: "100%" }}
              />
            </InputGroup>
          </FormGroup>
          <Button
            color="default"
            style={{ width: "100%" }}
            size="lg"
            onClick={() => this.props.callback(this.state.name)}
            disabled={!this.state.name || this.state.name === ""}
          >
            Next
          </Button>
        </Col>
      </Row>
    );
  }
}

GetName.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(GetName);
