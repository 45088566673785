import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
// reactstrap components
import { Card, Row, Col, Button } from "reactstrap";
import { emptyCurrentPhotoshoot } from "../../actions/photoshootActions";
import { ROUTE_TO_HOME } from "../../routes/routesConstants";
import history from "../../history";
import { COLORS, STYLES } from "../../style/Constants";
class Support extends React.Component {
  render() {
    return (
      <>
        <Row style={{ justifyContent: "center" }}>
          <Col
            lg="6"
            className="px-5 py-4"
            style={{ justifyContent: "center" }}
          >
            <Card
              className="justify-content-center"
              style={{
                borderRadius: 8,
                overflow: "hidden",
                ...STYLES.elevation,
              }}
            >
              <img
                alt="..."
                style={{
                  marginRight: "10px",
                  width: "100%",
                  height: 100,
                  objectFit: "cover",
                  objectPosition: "5px 40%",
                  backgroundColor: COLORS.accent_color,
                }}
                src={"/images/backgrounds/Taieri.svg"}
              />

              <Row>
                <Col lg="12" className="px-5 py-4">
                  <p className={"text-muted"} style={{ fontSize: "10pt" }}>
                    Contact us
                  </p>
                  <h3 className={"display-4"}>Support</h3>
                  The support platform is not integrated on our app yet. You can
                  contact us via our Facebook page.
                  <div className="mt-2">
                    <Button
                      color="primary"
                      size="md"
                      style={{ marginTop: "10px", float: "right" }}
                      href="https://m.me/100494948081246"
                    >
                      Contact us <i className="fi-sr-paper-plane"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

Support.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, emptyCurrentPhotoshoot })(
  Support
);
