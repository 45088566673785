import { combineReducers } from "redux";
import authReducer from "./authReducer";
import postReducer from "./postReducer";
import photoshootReducer from "./photoshootReducer";
import alertReducer from "./alertReducer";
import photographerReducer from "./photographerReducer";
export default combineReducers({
  auth: authReducer,
  post: postReducer,
  photoshoot: photoshootReducer,
  alert: alertReducer,
  photographer: photographerReducer,
});
