import React from "react";
import { Button } from "reactstrap";

import { Card, Row, Col, Progress } from "reactstrap";
import history from "../../../../history";
import { ROUTE_TO_EDIT_LOCATION } from "../../../../routes/routesProfile";
class SetupLocationAnnounce extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col xs="12">
            <div className="progress-wrapper">
              <div className="progress-info">
                <div className="progress-label">
                  <span>Registration</span>
                </div>
                <div className="progress-percentage">
                  <span>{this.props.percentage}%</span>
                </div>
              </div>
              <Progress
                max="100"
                value={this.props.percentage}
                color="default"
              />
            </div>
            <Card
              className="justify-content-center"
              color=""
              style={{ minHeight: "70%", padding: "30px" }}
            >
              <div style={{ textAlign: "center" }}>
                <h5> Add your location </h5>
                <p>
                  One last thing, we need to know your location. This can be
                  changed later.
                </p>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => history.push(ROUTE_TO_EDIT_LOCATION)}
                >
                  Complete my profile
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
export default SetupLocationAnnounce;
