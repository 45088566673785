// CheckoutForm.js
import React from "react";
import axios from "axios";
import { injectStripe } from "react-stripe-elements";
import { GET_ALERTS, SET_CURRENT_PHOTOSHOOT } from "../../actions/types";
import store from "../../store";
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
} from "react-stripe-elements";

import { Button, Row, Col, Spinner } from "reactstrap";
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { waitingPaymentValidation: false };
  }

  handleSubmit = async (ev) => {
    const launch_error = (error_message) => {
      this.setState({ waitingPaymentValidation: false });
      store.dispatch({
        type: GET_ALERTS,
        payload: {
          type: "error",
          message:
            error_message ||
            "An error occured with Stripe. Please contact support.",
        },
      });
    };
    this.setState({ waitingPaymentValidation: true });
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.props.stripe
      .createToken()
      .then((token) => {
        if (token.error) {
          launch_error(token.error.message);
        } else {
          axios
            .post(
              process.env.REACT_APP_SERVER_URI +
                "/api/payments/pay/" +
                this.props.current_photoshoot._id,
              {
                data: {
                  source: token.token.id,
                  receipt_email: "customer@example.com",
                },
              }
            )
            .then((payment) => {
              store.dispatch({
                type: SET_CURRENT_PHOTOSHOOT,
                payload: payment.data.data.photoshoot,
              });
              store.dispatch({
                type: GET_ALERTS,
                payload: {
                  type: "success",
                  message: "Your pictures are now unlocked!",
                },
              });
            })
            .catch((e) => {
              launch_error();
            });
        }
      })
      .catch((e) => {
        launch_error();
      });
  };

  render() {
    const style_labels = {
      fontWeight: "550",
      color: "#727272",
      fontSize: "11px",
    };
    return (
      <form onSubmit={this.handleSubmit}>
        <Row>
          <Col xs={12}>
            <label style={style_labels}>Card Number</label>
            <div
              style={{
                background: "#FFF",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <CardNumberElement
                style={{
                  width: "100%",
                  background: "#ffffff",
                }}
              />
            </div>
          </Col>
          <Col xs={6}>
            <label style={style_labels}>Expiration date</label>
            <div
              style={{
                background: "#FFF",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <CardExpiryElement />
            </div>
          </Col>
          <Col xs={6}>
            <label style={style_labels}>CVC</label>
            <div
              style={{
                background: "#FFF",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              <CardCvcElement />
            </div>
          </Col>
          <Col xs={12}>
            <Button
              color="primary"
              style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              disabled={this.state.waitingPaymentValidation}
            >
              {this.state.waitingPaymentValidation && (
                <Spinner size="sm" color="white" />
              )}
              Confirm order
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
