import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";
import { COLORS } from "../../style/Constants";
class WaitingForPayment extends React.Component {
  constructor(props) {
    super();
    this.state = {
      paymentMethod: false,
    };
  }
  render() {
    return (
      <Row className="justify-content-center">
        <Col lg="4" md="8" sm="12">
          <h5 style={{ marginBottom: "20px", fontWeight: 600 }}>
            Waiting for payment
          </h5>
          <p className="mb-3 mt--3 text-muted">
            We sent an email to {this.props.current_photoshoot.clientName},
            inviting them to pay online. If the client used another method, pick
            it in the list below.
          </p>
          <Row>
            <Col xs={6} className="py-3">
              <div
                className="py-2"
                style={{
                  textAlign: "center",
                  borderRadius: 15,
                  border:
                    "2px solid " +
                    (this.state.paymentMethod == "cash"
                      ? COLORS.text_black
                      : COLORS.grey_2),
                }}
                onClick={() => this.setState({ paymentMethod: "cash" })}
              >
                <img
                  style={{
                    width: 50,
                    marginBottom: 20,
                    alignSelf: "center",
                  }}
                  alt="..."
                  src={"/images/icons/common/money.svg"}
                />

                <h4 style={{ textAlign: "center", fontWeight: "800" }}>Cash</h4>
              </div>
            </Col>
            <Col xs={6} className="py-3">
              <div
                className="py-2"
                style={{
                  textAlign: "center",
                  borderRadius: 15,
                  border:
                    "2px solid " +
                    (this.state.paymentMethod == "venmo"
                      ? COLORS.text_black
                      : COLORS.grey_2),
                }}
                onClick={() => this.setState({ paymentMethod: "venmo" })}
              >
                <img
                  style={{
                    width: 50,
                    marginBottom: 20,
                    alignSelf: "center",
                  }}
                  alt="..."
                  src={"/images/icons/common/smartphone.svg"}
                />
                <h4 style={{ textAlign: "center", fontWeight: "800" }}>
                  Venmo
                </h4>
              </div>
            </Col>
          </Row>
          {this.state.paymentMethod && (
            <Button
              color="default"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => this.props.callback(this.state.paymentMethod)}
            >
              The client has paid me
            </Button>
          )}
          <Button
            color="grey"
            size="lg"
            style={{ width: "100%", marginTop: 15 }}
            onClick={() => this.props.callback(false)}
          >
            {" "}
            Go Home
          </Button>
        </Col>
      </Row>
    );
  }
}

WaitingForPayment.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(WaitingForPayment);
