import React from "react";
import { Button } from "reactstrap";

import { Card, Row, Col, Progress } from "reactstrap";
import { ROUTE_TO_ONBOARD_STRIPE } from "../../../../routes/routesConstants";
import { COLORS } from "../../../../style/Constants";
class SetupPaymentAnnounce extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col lg="5" md="12" xs="12" style={{ marginBottom: 55 }}>
            <Card
              className="justify-content-center px-3 py-5"
              color=""
              style={{ minHeight: "70%", backgroundColor: COLORS.grey_1 }}
            >
              <div
                style={{
                  paddingRight: 30,
                  paddingLeft: 30,
                }}
              >
                <img
                  style={{
                    width: 50,
                    marginBottom: 20,
                  }}
                  alt="..."
                  src={"/images/icons/common/money-bag-colored.svg"}
                />
                <h5 style={{ fontWeight: "700" }}>
                  {" "}
                  Configure how you receive your payments{" "}
                </h5>
                <p>
                  In order to receive payments, you need to setup your earnings
                  account. It takes 2 minutes.
                </p>
                <Button
                  style={{
                    marginTop: 20,

                    alignSelf: "flex-start",
                  }}
                  size="lg"
                  color="primary"
                  href={
                    "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
                    process.env.REACT_APP_FRONT_URI +
                    ROUTE_TO_ONBOARD_STRIPE +
                    "&client_id=" +
                    process.env.REACT_APP_CLIENT_ID +
                    "&scope={READ_WRITE}&stripe_user[business_type]=individual"
                  }
                >
                  Setup Earnings Account
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
export default SetupPaymentAnnounce;
