import { GET_ALERTS } from "../actions/types";

const initialState = null;

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALERTS:
      return (action.payload && action.payload) || "";
    default:
      return state;
  }
}
