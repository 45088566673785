import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, setAuthToken } from "../../../actions/authActions";
import { Spinner } from "reactstrap";
// reactstrap components
import { Row, Col } from "reactstrap";
import { ROUTE_TO_HOME, ROUTE_TO_LOGIN } from "../../../routes/routesConstants";

class ReceiveToken extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to ReceiveToken page, should redirect them to login with the tokens in the query parameters

    if (this.props.auth.isAuthenticated) {
      console.log("Already authenticated");
      this.props.history.push(ROUTE_TO_HOME);
    } else {
      // Handles if this is after 0auth
      let token = null;
      let refreshToken = null;
      let search = window.location.search;
      let params = new URLSearchParams(search);
      if (params) {
        // Retrieve the tokens from the query
        token = "Bearer " + params.get("token");
        refreshToken = params.get("refresh_token");
        if (token && refreshToken) {
          console.log("in ReceiveToken we set the token");
          setAuthToken(token, refreshToken, () =>
            this.props.history.push(ROUTE_TO_HOME)
          );
        }
      }
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            aligItems: "center",
            justifyContent: "center",
          }}
        >
          <Row className="justify-content-center">
            <Col lg="12" style={{ textAlign: "center" }}>
              <h3 className="display-3"> Logging you back...</h3>
              <p>
                {" "}
                It should not take more than a few seconds. If it takes too
                long, please <a href={ROUTE_TO_LOGIN}>Log In again</a>.
              </p>
              <Spinner size="lg" color="primary" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
ReceiveToken.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(ReceiveToken);
