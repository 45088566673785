import axios from "../axios";
import jwt_decode from "jwt-decode";
import store from "./../store";
import {
  GET_ALERTS,
  SET_CURRENT_USER,
  USER_LOADING,
  SET_PUBLIC_PROFILE,
} from "./types";
import { emptyCurrentPhotoshoot } from "./photoshootActions";
import { ROUTE_TO_LOGIN, ROUTE_TO_HOME } from "../routes/routesConstants";
import history from "../history";
// Register User
export const registerUser =
  (userData, type = "client", history) =>
  (dispatch) => {
    userData.type = type;
    axios
      .post(process.env.REACT_APP_SERVER_URI + "/api/auth/register", userData)
      .then((res) => {
        history.push(ROUTE_TO_LOGIN);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

// Patch User
export const patchUser = (userData, history) => (dispatch) => {
  axios
    .patch(
      process.env.REACT_APP_SERVER_URI + "/api/users/" + userData.id,
      userData
    )
    .then((res) => {
      // We refresh the content of the store
      dispatch(refreshToken());
      console.log("user updated");
      console.log("Creating new alert");
      dispatch({
        type: GET_ALERTS,
        payload: { type: "success", message: "Profile successfully updated!" },
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response);
    });
};

export const getPublicProfile = (id) => (dispatch) => {
  return axios
    .get(process.env.REACT_APP_SERVER_URI + "/api/users/public_profile/" + id)
    .then((res) => {
      dispatch({
        type: SET_PUBLIC_PROFILE,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_SERVER_URI + "/api/auth/login", userData)
    .then((res) => {
      // Save to localStorage
      console.log(res);
      // Set token to localStorage
      const token = res.data.token;
      const refreshToken = res.data.refresh_token;
      // Set token to Auth header
      var decoded = setAuthToken(token, refreshToken);
      // Decode token to get user data
      history.push(ROUTE_TO_HOME);
      return decoded;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const confirmEmail = (email, code) => (dispatch) => {
  axios
    .post(process.env.REACT_APP_SERVER_URI + "/api/users/confirm-email", {
      data: { email: email, code: code },
    })
    .then((res) => {
      history.push(ROUTE_TO_LOGIN);
    })
    .catch((err) => {
      console.log(err);
      history.push(ROUTE_TO_LOGIN);
    });
};

export const setAuthToken = (jwtToken, refreshToken, cb_success) => {
  // Except the jwttoken to be like : "Bearer adsfkbgasdif"
  if (jwtToken && refreshToken) {
    // Apply authorization token to every request if logged in

    localStorage.setItem("jwtToken", jwtToken);
    localStorage.setItem("refreshToken", refreshToken);
    axios.defaults.headers.common["Authorization"] = jwtToken;
    // Decode token to get user data
    const decoded = jwt_decode(jwtToken);
    // Set current user
    store.dispatch(setCurrentUser(decoded));
    if (cb_success) {
      console.log("[+] The token has been set");
      return cb_success();
    }
    return decoded;
  } else {
    // Delete auth header
    console.log("[!] Couldn't auth. Some tokens might be missing.");
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Used to set a JWT after OAuth
export const setJWTtoken = (raw_token) => {
  console.log("setJWTtoken");
  if (raw_token !== undefined) {
    let token = "Bearer " + raw_token;
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    store.dispatch(setCurrentUser(decoded));
  }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  console.log("[+] Setting current user in store");
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  console.log("Setting user loading");
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");
  // Remove auth header for future requests
  setAuthToken(false, false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(emptyCurrentPhotoshoot({}));
};

export const refreshToken = (cb_success) => (dispatch) => {
  if (!localStorage.getItem("refreshToken")) {
    console.log("Error Refreshing the session");
    dispatch({
      type: GET_ALERTS,
      payload: { type: "error", message: "Unable to refresh the session." },
    });
  }
  axios
    .post(process.env.REACT_APP_SERVER_URI + "/api/auth/refresh", {
      refresh_token: localStorage.getItem("refreshToken"),
    })
    .then((res) => {
      console.log("[+] Refreshing user done.");
      var jwtToken = res.data.token;
      var refreshToken = res.data.refresh_token;
      var decoded = setAuthToken(jwtToken, refreshToken);
      //console.log(decoded);
      if (cb_success) {
        return cb_success(decoded);
      }
      return decoded;
    })
    .catch((err) => {
      console.log(err);
      console.log("[!] Failed Refreshing user.");
      dispatch(logoutUser());
      // Redirect to login
      history.push(ROUTE_TO_LOGIN);
      return err;
    });
};
