import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import history from "../../../history";
// reactstrap components
import { Button, Card, Row, Spinner, Col } from "reactstrap";
import {
  ROUTE_TO_HOME,
  ROUTE_TO_ONBOARD_STRIPE,
} from "../../../routes/routesConstants";
import axios from "../../../axios";
import {
  ROUTE_TO_EDIT_LOCATION,
  ROUTE_TO_EDIT_PRICE,
  ROUTE_TO_EDIT_PROFILE,
} from "../../../routes/routesProfile";
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStripeButton: "",
      id: this.props.auth.user.id,
    };
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onAccessStripeDashboard() {
    console.log("trying to access to dasboard");
    this.setState({ loadingStripeButton: true });
    axios
      .get(
        process.env.REACT_APP_SERVER_URI +
          "/api/payments/connect/accessdashboard/" +
          this.state.id
      )
      .then((res) => {
        this.setState({ loadingStripeButton: false });
        var open = window.open(res.data.data.link_url, "_blank");
        if (open == null || typeof open == "undefined") {
          console.log("Can't open a new page. Probably on safari.");
          window.location.href = res.data.data.link_url;
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loadingStripeButton: false });
      });
  }
  render() {
    const { user } = this.props.auth;
    return (
      <>
        <Row>
          <Col xs={12}>
            <div>
              <div>
                <p>
                  Email
                  <br />
                  <span style={{ color: "#000", fontWeight: "500" }}>
                    {user.email}
                  </span>
                </p>
              </div>
            </div>

            {!user.isPriceHidden && (
              <div>
                {user.type &&
                  user.type.includes("photographer") &&
                  (user.stripeAccountId && user.stripeAccountId !== "" ? (
                    <div>
                      <hr style={{ marginTop: 0 }}></hr>
                      <p>
                        <span
                          style={{
                            color: "#000",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Earnings Account
                        </span>

                        <p
                          style={{
                            fontWeight: "300",
                            fontSize: "12px",
                            marginTop: "10px",
                          }}
                        >
                          The account management is handled by our partner
                          Stripe.
                        </p>
                      </p>

                      <Button
                        color="primary"
                        size="md"
                        className="my-3"
                        onClick={() => this.onAccessStripeDashboard()}
                      >
                        {this.state.loadingStripeButton && (
                          <Spinner size="sm" color="white" />
                        )}{" "}
                        Manage my account
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <hr></hr>
                      <p>Earnings Account</p>

                      <Button
                        color="primary"
                        size="lg"
                        href={
                          "https://connect.stripe.com/express/oauth/authorize?redirect_uri=" +
                          process.env.REACT_APP_FRONT_URI +
                          ROUTE_TO_ONBOARD_STRIPE +
                          "&client_id=" +
                          process.env.REACT_APP_CLIENT_ID +
                          "&scope={READ_WRITE}&stripe_user[business_type]=individual&stripe_user[country]=US&stripe_user[url]=https://www.yoov.io/yoover/" +
                          this.props.auth.user.id
                        }
                      >
                        Setup Bank Account
                      </Button>
                    </div>
                  ))}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

Profile.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Profile);
