import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import history from "../../history";
// reactstrap components
import { Button, Card, Row, Col } from "reactstrap";
import { emptyCurrentPhotoshoot } from "../../actions/photoshootActions";
import { ROUTE_TO_HOME, ROUTE_TO_SUPPORT } from "../../routes/routesConstants";
class NotPermission extends React.Component {
  render() {
    return (
      <>
        <section
          className="section"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card className="justify-content-center" style={{ minHeight: "70%" }}>
            <Row>
              <Col lg="12" style={{ padding: "50px" }}>
                <h3>401 Not Allowed</h3>
                <p>You are not allowed to access this resource...</p>
                <Button
                  color="default"
                  onClick={() => history.push(ROUTE_TO_HOME)}
                  size="lg"
                  style={{ marginTop: "10px", width: "100%" }}
                >
                  Go Home
                </Button>
                <Button
                  color="white"
                  onClick={() => history.push(ROUTE_TO_SUPPORT)}
                  size="lg"
                  style={{ marginTop: "10px", width: "100%" }}
                >
                  Report an issue to the support
                </Button>
              </Col>
            </Row>
          </Card>
        </section>
      </>
    );
  }
}

NotPermission.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, emptyCurrentPhotoshoot })(
  NotPermission
);
