import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";

import { Button, Col, Row, Table } from "reactstrap";
import {
  addPhotoshoot,
  emptyCurrentPhotoshoot,
  listPhotoshoot,
  getPhotoshoot,
} from "../../../../actions/photoshootActions";
import history from "../../../../history";
// reactstrap components
import store from "../../../../store";
import CardPhotoshoot from "../../../../components/layout/CardPhotoshoot";
import {
  ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER,
  ROUTE_TO_HOME,
} from "../../../../routes/routesConstants";
import axios from "../../../../axios";
import Moment from "moment";
import { COLORS, STYLES } from "../../../../style/Constants";
import { mock_list_photoshoots } from "../../../../helpers/global";
const is_mobile = window.innerWidth < 768;
class ListPhotoshoot extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.listPhotoshoot(0);
  }

  gotToEditPhotoshoot(id) {
    history.push(ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER + "/" + id);
  }
  render() {
    const badges_per_step = {
      getName: <span class="badge badge-warning">Uncomplete</span>,
      setPrice: <span class="badge badge-warning">Uncomplete</span>,
      shoot: <span class="badge badge-warning">Uncomplete</span>,
      joinClient: <span class="badge badge-warning">Uncomplete</span>,
      uploadPictures: <span class="badge badge-warning">Uncomplete</span>,
      buyPictures: <span class="badge badge-secondary">Complete</span>,
      paymentDone: <span class="badge badge-success">Succeeded</span>,
      default: "ni ni-settings-gear-63-2",
    };

    const is_using_mock_data = !(
      this.props.photoshoot.listphotoshoot?.length > 0
    );

    const list_photoshoot = is_using_mock_data
      ? mock_list_photoshoots
      : this.props.photoshoot?.listphotoshoot;
    console.log(listPhotoshoot);
    return (
      <>
        <Row
          style={{
            marginBottom: 21,
            marginTop: 21,
            paddingLeft: 34,
            paddingRight: 34,
            flex: 0,
            alignItems: "center",
          }}
        >
          <Col sm={12}>
            <h5 className="display-4 text-dark" style={{ fontWeight: "800" }}>
              Photoshoots{" "}
            </h5>
          </Col>
        </Row>

        <div
          style={{
            backgroundColor: "#fff",
            padding: is_mobile ? 0 : 0,
            flex: 1,
          }}
        >
          {is_using_mock_data && (
            <div
              className="px-4 py-3"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 150,
                ...STYLES.elevation,
                borderRadius: 8,
                margin: "auto",
                backgroundColor: COLORS.grey_2,
                zIndex: 10,
                width: 300,
                textAlign: "center",
                opacity: 1,
              }}
            >
              <p style={{ fontWeight: "600" }}>No photoshoot to display yet.</p>
              <p style={{ fontWeight: "400", fontSize: 13 }}>
                Once you start your photoshoots, you'll be able to manage them
                here.
              </p>
            </div>
          )}
          <Table
            responsive
            hover={true}
            style={{ opacity: is_using_mock_data ? 0.2 : 1 }}
          >
            <thead>
              <tr
                style={{
                  fontSize: "0.8em",
                  textTransform: "uppercase",
                  color: COLORS.text_black_secondary,
                }}
              >
                <th scope="col" style={{ border: "none" }}>
                  Client
                </th>
                <th scope="col" style={{ border: "none" }}>
                  Price
                </th>
                {is_mobile ? null : (
                  <th scope="col" style={{ border: "none" }}>
                    Location
                  </th>
                )}
                <th scope="col" style={{ border: "none" }}>
                  Date
                </th>
                <th scope="col" style={{ border: "none" }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                fontWeight: "500",
                fontSize: is_mobile ? "0.7em" : "0.9em",
              }}
            >
              {list_photoshoot.map((photoshoot, index) => (
                <tr
                  onClick={() =>
                    !is_using_mock_data &&
                    this.gotToEditPhotoshoot(photoshoot._id)
                  }
                >
                  <td>
                    <span style={{ fontWeight: 600 }}>
                      {photoshoot.clientName}
                    </span>
                  </td>
                  <td>
                    {photoshoot.price ? (
                      <>
                        <span style={{ fontWeight: 600 }}>
                          ${photoshoot.price}
                        </span>{" "}
                        <span className="text-muted">USD</span>
                      </>
                    ) : (
                      <>-</>
                    )}{" "}
                  </td>

                  {is_mobile ? null : (
                    <td>
                      <p>{photoshoot.location?.split(",")[0]}</p>
                    </td>
                  )}
                  <td>
                    {is_mobile
                      ? Moment(photoshoot.createdAt).format("l")
                      : Moment(photoshoot.createdAt).format("LLL")}
                  </td>
                  <td>{badges_per_step[photoshoot.step]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

ListPhotoshoot.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  addPhotoshoot: PropTypes.func.isRequired,
  listPhotoshoot: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  logoutUser,
  addPhotoshoot,
  listPhotoshoot,
  emptyCurrentPhotoshoot,
  getPhotoshoot,
})(ListPhotoshoot);
