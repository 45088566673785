import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { patchUser } from "../../../actions/authActions";
// reactstrap components
import { Button, Card, Row, Col, NavItem, Nav, NavLink } from "reactstrap";
import store from "./../../../store";

import { GET_ALERTS } from "./../../../actions/types";
import { ROUTE_TO_PROFILE } from "../../../routes/routesConstants";
// reactstrap components
import { FormGroup, Form, Input, InputGroupText, InputGroup } from "reactstrap";
import { COLORS } from "../../../style/Constants";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      loadingStripeButton: "",
      id: user.id,
      email: user.email,
      password: "",
      password2: "",
      name: user.name,
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      id: this.state.id,
      email: this.state.email,
      name: this.state.name,
    };
    // If also modified password
    if (this.state.password !== "") {
      if (this.state.password !== this.state.password2) {
        store.dispatch({
          type: GET_ALERTS,
          payload: { type: "error", message: "Passwords are not matching" },
        });
      } else {
        userData.password = this.state.password;

        this.props.patchUser(userData);
      }
    } else {
      this.props.patchUser(userData);
    }
  };

  render() {
    var user = this.props.auth.user;
    return (
      <>
        {" "}
        <Form role="form" noValidate onSubmit={this.onSubmit}>
          <FormGroup className="mb-3">
            <p
              style={{
                fontSize: "12px",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Name
            </p>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-single-01-2" />
              </InputGroupText>
              <Input
                placeholder="Name"
                type="input"
                onChange={this.onChange}
                value={this.state.name}
                id="name"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-3">
            <p
              style={{
                fontSize: "12px",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Email
            </p>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-email-84-2" />
              </InputGroupText>
              <Input
                placeholder="Email"
                type="email"
                onChange={this.onChange}
                value={this.state.email}
                id="email"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "700",
                marginBottom: "10px",
              }}
            >
              Password
            </p>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-lock" />
              </InputGroupText>
              <Input
                placeholder="Password"
                type="password"
                autoComplete="off"
                onChange={this.onChange}
                value={this.state.password}
                id="password"
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupText>
                <i className="ni ni-lock" />
              </InputGroupText>
              <Input
                placeholder="Re-enter Password"
                type="password"
                autoComplete="off"
                onChange={this.onChange}
                value={this.state.password2}
                id="password2"
              />
            </InputGroup>
          </FormGroup>

          <Button
            className="my-4"
            color="primary"
            type="submit"
            style={{ boxShadow: "none" }}
          >
            Save Profile
            <i
              className={"fi-rr-angle-small-right"}
              style={{ marginLeft: 8, fontSize: 12 }}
            ></i>
          </Button>
        </Form>
      </>
    );
  }
}

EditProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  patchUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { patchUser })(EditProfile);
