import React from "react";
import history from "../../../../history";
import { Card, Row, Col, Button } from "reactstrap";
import {
  computePrice,
  mock_list_photoshoots,
} from "../../../../helpers/global";
import Moment from "moment";
import store from "./../../../../store";
import {
  emptyCurrentPhotoshoot,
  listPhotoshoot,
  getPhotoshoot,
} from "../../../../actions/photoshootActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER,
  ROUTE_TO_PHOTOSHOOT_LIST,
} from "../../../../routes/routesConstants";
import { Line, Bar } from "react-chartjs-2";
import CardPhotoshoot from "../../../../components/layout/CardPhotoshoot";
import { badges_per_step } from "../../../../style/StyleHelpers";
import Chart from "chart.js";
import {
  chartExample1,
  chartExample2,
  parseOptions,
  chartOptions,
} from "../charts/charts";
import { ROUTE_TO_EDIT_PRICE } from "../../../../routes/routesProfile";
import { COLORS } from "../../../../style/Constants";
class DefaultAnnounce extends React.Component {
  componentDidMount() {
    this.props.listPhotoshoot(0);
  }
  onClickNewPhotoshoot = () => {
    this.props.emptyCurrentPhotoshoot();
    history.push(ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER);
  };
  gotToEditPhotoshoot(id) {
    store.dispatch(getPhotoshoot(id)).then(() => {
      history.push(ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER);
    });
  }
  render() {
    parseOptions(Chart, chartOptions());

    const is_using_mock_data = !(
      this.props.photoshoot.listphotoshoot?.length > 0
    );

    const list_photoshoot = is_using_mock_data
      ? mock_list_photoshoots
      : this.props.photoshoot?.listphotoshoot;
    return (
      <>
        <Row>
          <Col sm={12} md={12} lg={6} xl={4}>
            {this.props.photoshoot.listphotoshoot &&
              this.props.photoshoot.listphotoshoot &&
              this.props.photoshoot.listphotoshoot.length === 0 && (
                <Card
                  style={{
                    padding: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    style={{
                      width: 60,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                    alt="..."
                    src={"/images/icons/common/check.svg"}
                  />
                  <h5 style={{ fontWeight: "800" }}>Profile Completed!</h5>
                  <p>You are ready to photograph and earn money!</p>
                  <p>
                    We will now have an expert walk you though your installation
                    of our mobile app.
                  </p>
                  <Button
                    style={{ marginTop: 20, alignSelf: "flex-start" }}
                    color="primary"
                    href="https://m.me/100494948081246"
                  >
                    Contact an expert
                  </Button>
                </Card>
              )}

            {/*<Row>
              <Col xs={12}>
                <Card style={{ padding: 21 }}>
                  <p style={{ marginBottom: 13 }}>Earnings</p>
                  <div className="chart">
                    <Line
                      data={chartExample1.data1}
                      options={chartExample1.options}
                    />
                  </div>
                </Card>
              </Col>
            </Row>*/}
          </Col>
          {/*
          <Col sm={12} md={12} lg={3} xl={4}>
            <Card
              style={{ padding: 21 }}
              className="bg-gradient-primary text-white"
            >
              <p style={{ fontWeight: 700 }}>Help us improve yoov</p>
              <p>What type of feature would you like to have on yoov?</p>
              <div>
                <Button color="white" size="md" className="mt-2">
                  Request a feature{"  "}
                  <i className={"fa fa-chevron-right text-muted ml-1"}></i>
                </Button>
              </div>
            </Card>
          </Col>*/}
        </Row>

        <Row>
          {list_photoshoot && (
            <>
              <Col xs={12}>
                <p className="text-muted" style={{ fontSize: 12 }}>
                  Recent Activity
                </p>
                <h5 style={{ marginBottom: 8 }}>
                  My Photoshoots
                  <span
                    className="text-primary hoverable"
                    style={{
                      float: "right",
                      fontSize: 12,
                      fontWeight: "600",
                    }}
                    onClick={() => history.push(ROUTE_TO_PHOTOSHOOT_LIST)}
                  >
                    VIEW ALL <i className="fi-rr-arrow-small-right"></i>
                  </span>
                </h5>
              </Col>
              {list_photoshoot.slice(0, 4).map((item) => (
                <Col
                  className=""
                  xl="3"
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  photoshoot={item}
                  onClick={() =>
                    !is_using_mock_data && this.gotToEditPhotoshoot(item._id)
                  }
                  style={{
                    paddingTop: 13,
                    paddingBottom: 13,
                    opacity: is_using_mock_data ? 0.3 : 1,
                  }}
                >
                  <Card
                    className={
                      is_using_mock_data ? "" : "card-lift--hover hoverable "
                    }
                    style={{ padding: 20, backgroundColor: COLORS.grey_1 }}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col xs="9">
                        <p className="text-muted" style={{ fontSize: "0.8em" }}>
                          {Moment(item.createdAt).fromNow()}
                        </p>
                        <p style={{ fontWeight: 600, marginBottom: 0 }}>
                          {item.clientName}
                        </p>
                        <div>{badges_per_step[item.step]}</div>
                      </Col>
                      <Col xs="3">
                        <i className={"fa fa-chevron-right text-muted"}></i>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
        {/*  <div style={{ display: "flex", flex: "1" }}>
          <Button
            className="float-right"
            color="default"
            style={{ marginTop: "40px" }}
            size="lg"
            onClick={() => this.onClickNewPhotoshoot()}
          >
            <i className={"fa fa-plus "}></i> new photoshoot
          </Button>
                  </div>*/}
      </>
    );
  }
}

DefaultAnnounce.propTypes = {
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  listPhotoshoot,
  emptyCurrentPhotoshoot,
  getPhotoshoot,
})(DefaultAnnounce);
