import React from "react";
import SetupPaymentAnnounce from "./SetupPaymentAnnounce";
import DefaultAnnounce from "./DefaultAnnounce";
import { connect } from "react-redux";
import SetupLocationAnnounce from "./SetupLocationAnnounce";
class HomeAnnounce extends React.Component {
  render() {
    return (
      <>
        {!this.props.auth.user.stripeAccountId ||
        this.props.auth.user.stripeAccountId === "" ? (
          <SetupPaymentAnnounce percentage="90" />
        ) : (
          <DefaultAnnounce user={this.props.auth.user} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HomeAnnounce);
