import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { setAuthToken, refreshToken } from "./actions/authActions";
import { setCurrentUser } from "./actions/authActions";
import store from "./store";
import { Spinner } from "reactstrap";
import { Col } from "reactstrap";
import Div100vh from "react-div-100vh";
import { connect } from "react-redux";
import FlashAlert from "./components/layout/FlashAlert";
import PrivateRoute from "./components/private-route/PrivateRoute";
import {
  ROUTE_TO_LOGIN,
  ROUTE_TO_REGISTER,
  ROUTE_TO_HOME,
  ROUTE_TO_LOGOUT,
  ROUTE_TO_RECEIVE_TOKEN,
  ROUTE_TO_ONBOARD_CLIENT,
  ROUTE_TO_PROFILE,
  ROUTE_TO_EDIT_PRICE,
  ROUTE_TO_EDIT_LOCATION,
  ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER,
  ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT,
  ROUTE_TO_SUPPORT,
  ROUTE_TO_ONBOARD_STRIPE,
  ROUTE_TO_PHOTOSHOOT_LIST,
  ROUTE_TO_NOT_ALLOWED,
  ROUTE_TO_REGISTER_PHOTOGRAPHER,
  ROUTE_TO_VERIFY_EMAIL,
  ROUTE_TO_REGISTER_BETA,
} from "./routes/routesConstants";
import Register from "./views/authentication/register/Register";
import OnboardingNewClient from "./views/authentication/register/OnboardingNewClient";
import VerifyEmail from "./views/authentication/register/VerifyEmail";
import Logout from "./views/authentication/profile/Logout";
import Login from "./views/authentication/login/Login";
import ReceiveToken from "./views/authentication/token/ReceiveToken";
import Profile from "./views/authentication/profile/Profile";
import EditProfile from "./views/authentication/profile/EditProfile";
import EditLocation from "./views/authentication/profile/EditLocation";
import EditPrice from "./views/authentication/profile/EditPrice";
import HomepageClient from "./views/dashboard/client/homePage/HomepageClient";
import Homepage from "./views/dashboard/photographer/homePage/Homepage";
import StepOrchestratorClient from "./views/dashboard/client/photoshootProcess/StepOrchestratorClient";
import StepOrchestratorPhotographer from "./views/dashboard/photographer/photoshoot/StepOrchestratorPhotographer";
import OnboardingStripe from "./views/dashboard/stripe/OnboardingStripe";
import ListPhotoshootClient from "./views/dashboard/client/photoshootProcess/ListPhotoshootClient";
import ListPhotoshoot from "./views/dashboard/photographer/photoshoot/ListPhotoshoot";
import NotFound from "./views/errorPages/NotFound";
import NotPermission from "./views/errorPages/NotPermission";
import Support from "./views/support/Support";
import BottomNavbar from "./components/navbars_/BottomNavbar";
import LayoutDashboard from "./components/layout/LayoutDashboard";
import ProfileContainer from "./views/authentication/profile/ProfileContainer";
import RegisterBeta from "./views/authentication/register/RegisterBeta";

class ConnectedApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      loading: true,
      match: this.props.match,
      lock_did_update_for_token_refresh: true,
    };
    this.autoRefreshToken = this.autoRefreshToken.bind(this);
    this.checkToken = this.checkToken.bind(this);
  }

  componentDidMount() {
    console.log("[i] Component Mounted");
    this.checkToken();
  }

  componentDidUpdate(prevprops) {
    // if before there was no user and now the component receive one
    // if auth has been updating ( after google auth for instance )
    if (prevprops.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      console.log("[i] Auth changed in Did Update");
      if (!this.state.lock_did_update_for_token_refresh) {
        this.checkToken();
      } else {
        console.log("[!] Locked");
      }
    }
  }

  checkToken() {
    console.log("[i] Checking token");
    if (
      localStorage.jwtToken &&
      localStorage.jwtToken !== "Bearer null" &&
      localStorage.refreshToken
    ) {
      // Set auth token header auth
      const token = localStorage.jwtToken;
      const refresh_token = localStorage.refreshToken;

      const decoded = setAuthToken(token, refresh_token);
      // Handles for expired token
      this.autoRefreshToken(decoded);
    } else {
      console.log("[!] No token");
      console.log(localStorage.jwtToken);
      this.setState({
        loading: false,
        lock_did_update_for_token_refresh: false,
      });
    }
  }

  autoRefreshToken(decoded, at_mount) {
    store.dispatch(setCurrentUser(decoded));
    // refresh the render of the ConnectedApp
    this.setState({ token: decoded });
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // if the token is expired, we try to refresh it
      // at the refresh of the page
      console.log("[!] Token Expired");
      store.dispatch(
        refreshToken(this.autoRefreshToken, () =>
          this.setState({ loading: false })
        )
      );
    } else {
      this.setState({ loading: false });
      console.log("[+] Started the autorefresh");

      setTimeout(() => {
        console.log("[i] Auto refresh of the token");

        store.dispatch(refreshToken(this.autoRefreshToken));
        // we keep some margin, to refresh with half of the token's lifetime remaining available
      }, Math.round((decoded.exp - currentTime) / 2) * 1000);
    }
  }

  render() {
    return (
      <div className="App">
        <main className="homepage" ref="main">
          <Div100vh
            style={{
              height: "100rvh",
              display: "flex",
              flexDirection: "column",
              padding: 0,
              paddingTop: "10px",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
                flex: 9,
              }}
            >
              <FlashAlert />
              <LayoutDashboard>
                {this.state.loading === true ? (
                  <div style={{ textAlign: "center" }}>
                    <Spinner size="lg" color="primary" />
                  </div>
                ) : (
                  <Switch>
                    <Route exact path={ROUTE_TO_REGISTER} component={Register}>
                      <Register type="client" />
                    </Route>
                    <Route
                      exact
                      path={ROUTE_TO_REGISTER_PHOTOGRAPHER}
                      component={Register}
                    >
                      <Register type="photographer" />
                    </Route>
                    <Route exact path={ROUTE_TO_REGISTER_BETA}>
                      <RegisterBeta type="photographer" />
                    </Route>
                    <Route
                      exact
                      path={ROUTE_TO_VERIFY_EMAIL + "/:email/:code"}
                      component={VerifyEmail}
                    ></Route>
                    <Route exact path={ROUTE_TO_LOGIN} component={Login} />
                    <Route exact path={ROUTE_TO_LOGOUT} component={Logout} />
                    <Route
                      exact
                      path={ROUTE_TO_RECEIVE_TOKEN}
                      component={ReceiveToken}
                    />
                    <Route
                      exact
                      path={ROUTE_TO_ONBOARD_CLIENT + "/:email/:name"}
                      component={OnboardingNewClient}
                    />
                    <PrivateRoute
                      path={ROUTE_TO_PROFILE}
                      component={ProfileContainer}
                    />

                    <PrivateRoute
                      exact
                      path={ROUTE_TO_HOME}
                      component={
                        store.getState().auth.user.type &&
                        store.getState().auth.user.type.includes("client")
                          ? HomepageClient
                          : Homepage
                      }
                    />
                    <PrivateRoute
                      typeofuser="photographer"
                      exact
                      path={
                        ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER +
                        "/:photoshootid?/:step?"
                      }
                      component={StepOrchestratorPhotographer}
                    />
                    <PrivateRoute
                      typeofuser="client"
                      exact
                      path={
                        ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT + "/:photoshootid"
                      }
                      component={StepOrchestratorClient}
                    />
                    <Route exact path={ROUTE_TO_SUPPORT} component={Support} />
                    <PrivateRoute
                      exact
                      path={ROUTE_TO_NOT_ALLOWED}
                      component={NotPermission}
                    />
                    <PrivateRoute
                      typeofuser="photographer"
                      exact
                      path={ROUTE_TO_ONBOARD_STRIPE}
                      component={OnboardingStripe}
                    />
                    <PrivateRoute
                      exact
                      path={ROUTE_TO_PHOTOSHOOT_LIST}
                      component={
                        store.getState().auth.user.type &&
                        store.getState().auth.user.type.includes("client")
                          ? ListPhotoshootClient
                          : ListPhotoshoot
                      }
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              </LayoutDashboard>
            </div>
            {/* 
            <div
              style={{
                display: "flex",
                flex: "1",
                minHeight: "70px",
                flexDirection: "column",
                aligItems: "center",
                justifyContent: "end",
              }}
            >
              <BottomNavbar />
            </div>*/}
          </Div100vh>
          {/*<SimpleFooter /> */}
        </main>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});
export default connect(mapStateToProps, {})(ConnectedApp);
