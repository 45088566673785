import React from "react";

import { Card, Row, Col, Button } from "reactstrap";
import GalleryPreview from "./GalleryPreview";
import Moment from "moment";
class TimelineCardPhotoshoot extends React.Component {
  render() {
    const photographer_name =
      this.props.photoshoot.photographerName || "The photographer";
    const icons_per_step = {
      getName: "ni ni-single-01-2",
      setPrice: "ni ni-settings-gear-63-2",
      shoot: "ni ni-camera-20",
      joinClient: "ni ni-ic_person_add_48px",
      uploadPictures: "ni ni-ic_file_download_48px",
      buyPictures: "ni ni-lock",
      paymentDone: "ni ni-cart-simple",
      default: "ni ni-settings-gear-63-2"
    };
    const message_per_step = {
      shoot: "The shooting finished.",
      joinClient: "The shooting finished.",
      uploadPictures: photographer_name + " registered the photoshoot.",
      buyPictures: photographer_name + " just uploaded the pictures.",
      paymentDone: "You unlocked the pictures.",
      default: photographer_name + " is still configuring..."
    };

    const todo_per_step = {
      getName: photographer_name + " is still configuring.",
      setPrice: photographer_name + " is still configuring.",
      shoot: "The pictures will be uploaded soon.",
      joinClient: "The pictures will be uploaded soon.",
      uploadPictures: "The pictures will be uploaded soon.",
      buyPictures: "Unlock the pictures now!",
      paymentDone: "Download your pictures.",
      default: photographer_name + " is still configuring."
    };

    const action_per_step = {
      buyPictures: "Unlock the pictures",
      paymentDone: "View all the pictures",
      default: "View"
    };
    const todo =
      todo_per_step[this.props.photoshoot.step] || todo_per_step["default"];
    const action =
      action_per_step[this.props.photoshoot.step] || action_per_step["default"];
    const icon =
      icons_per_step[this.props.photoshoot.step] || icons_per_step["default"];
    return (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: "12px",
            padding: "10px",
            marginBottom: "20px"
          }}
        >
          <span class="text-primary"></span>
        </div>

        {this.props.photoshoot.picturesList &&
          this.props.photoshoot.picturesList.length > 0 ? (
            <Card
              onClick={() =>
                this.props.onClickPhotoshoot(this.props.photoshoot._id)
              }
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "20px",
                paddingBottom: "20px",
                marginBottom: "20px"
              }}
            >
              <Row>
                <Col xs="10">
                  <p
                    style={{
                      lineHeight: "50px",
                      marginBottom: 0
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.photoshoot.location &&
                        this.props.photoshoot.location.split(",")[0]}
                    </span>
                  , by {this.props.photoshoot.photographerName}
                  </p>
                  <span
                    style={{
                      color: "#ACACAC",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-5px"
                    }}
                  >
                    {Moment(this.props.photoshoot.createdAt).calendar()}
                  </span>
                </Col>
                <Col xs="2">
                  <div
                    style={{
                      float: "right",
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      borderRadius: "50%",
                      verticalAlign: "middle"
                    }}
                  >
                    <p
                      style={{
                        lineHeight: "50px"
                      }}
                    >
                      <span className="text-muted">
                        <i className={icon}></i>
                      </span>
                    </p>
                  </div>
                </Col>
                <Col xs={12} style={{ marginTop: "30px" }}>
                  <GalleryPreview
                    link_locked={true}
                    locked={this.props.photoshoot.step !== "paymentDone"}
                    picturesList={this.props.photoshoot.picturesList}
                  />
                </Col>
              </Row>
              <Button
                className="float-right"
                color="default"
                style={{ width: "100%", marginTop: "10px" }}
                size="lg"
              >
                {action}
              </Button>
            </Card>
          ) : (
            <Card
              onClick={() =>
                this.props.onClickPhotoshoot(this.props.photoshoot._id)
              }
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "20px",
                paddingBottom: "20px",
                marginBottom: "20px"
              }}
            >
              <Row>
                <Col xs="10">
                  <p
                    style={{
                      lineHeight: "50px",
                      marginBottom: 0
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.photoshoot.clientName}
                    </span>
                  ,
                  {this.props.photoshoot.location &&
                      this.props.photoshoot.location.split(",")[0]}
                  </p>
                </Col>
                <Col xs="2">
                  <div
                    style={{
                      float: "right",
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      borderRadius: "50%",
                      verticalAlign: "middle"
                    }}
                  >
                    <p
                      style={{
                        lineHeight: "50px"
                      }}
                    >
                      <span className="text-muted">
                        <i className={icon}></i>
                      </span>
                    </p>
                  </div>
                </Col>
                <Col xs={12}>
                  <span
                    style={{
                      color: "#ACACAC",
                      fontSize: "15px",
                      marginTop: "-10px"
                    }}
                  >
                    {todo}
                  </span>
                </Col>
              </Row>
            </Card>
          )}
      </>
    );
  }
}

export default TimelineCardPhotoshoot;
