import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { confirmEmail } from "../../../actions/authActions";
import { Spinner } from "reactstrap";
// reactstrap components
import { Row, Col } from "reactstrap";
import {
  ROUTE_TO_HOME,
  ROUTE_TO_SUPPORT,
} from "../../../routes/routesConstants";
import store from "../../../store";
import { GET_ALERTS } from "../../../actions/types";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to VerifyEmail page, should redirect them to login with the tokens in the query parameters

    if (this.props.auth.isAuthenticated) {
      this.props.history.push(ROUTE_TO_HOME);
    } else {
      // Handles if this is after 0auth
      let email = this.props.match.params.email;
      let code = this.props.match.params.code;
      console.log(this.props.match);
      if (!email || !code) {
        store.dispatch({
          type: GET_ALERTS,
          payload: {
            type: "error",
            message: "The link is broken. Please contact support.",
          },
        });
        this.props.history.push(ROUTE_TO_SUPPORT);
      }

      this.props.confirmEmail(email, code);
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            aligItems: "center",
            justifyContent: "center",
          }}
        >
          <Row className="justify-content-center">
            <Col lg="12" style={{ textAlign: "center" }}>
              <h3 className="display-3"> Verifying the email adress...</h3>
              <p>
                {" "}
                It should not take more than a few seconds. If it takes too
                long, please <a href={ROUTE_TO_SUPPORT}> contact the support</a>
                .
              </p>
              <Spinner size="lg" color="primary" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
VerifyEmail.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { confirmEmail })(VerifyEmail);
