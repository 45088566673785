import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
// reactstrap components
import { Button, Card, Row, Col } from "reactstrap";
import { emptyCurrentPhotoshoot } from "../../actions/photoshootActions";
import history from "../../history";
import { ROUTE_TO_HOME, ROUTE_TO_SUPPORT } from "../../routes/routesConstants";
class NotFound extends React.Component {
  render() {
    return (
      <>
        <Row style={{ justifyContent: "center" }}>
          <Col lg="6" style={{ padding: "50px", justifyContent: "center" }}>
            <Card style={{ padding: 34 }}>
              <h3 className="display-3">404 - Not Found</h3>
              <p>This page does not seem to exist...</p>
              <div>
                <Button
                  color="primary"
                  onClick={() => history.push(ROUTE_TO_HOME)}
                  size="md"
                  style={{ marginTop: "10px" }}
                >
                  Go Home
                </Button>
                <Button
                  color="white"
                  onClick={() => history.push(ROUTE_TO_SUPPORT)}
                  size="md"
                  style={{ marginTop: "10px" }}
                >
                  Report an issue to the support
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

NotFound.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, emptyCurrentPhotoshoot })(
  NotFound
);
