import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import { Spinner } from "reactstrap";
import {
  addPhotoshoot,
  emptyCurrentPhotoshoot,
  listPhotoshootClient,
  getPhotoshoot,
} from "../../../../actions/photoshootActions";
import history from "../../../../history";
// reactstrap components
import CardPhotoshoot from "../../../../components/layout/CardPhotoshoot";
import { ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT } from "../../../../routes/routesConstants";

class ListPhotoshoot extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.listPhotoshootClient(0);
  }

  gotToEditPhotoshoot(id) {
    history.push(ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT + "/" + id);
  }
  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ minWidth: "90%" }}>
            {this.props.photoshoot.listphotoshoot ? (
              this.props.photoshoot.listphotoshoot.length > 0 ? (
                this.props.photoshoot.listphotoshoot.map(
                  (photoshoot, index) => (
                    <CardPhotoshoot
                      photoshoot={photoshoot}
                      onClick={() => this.gotToEditPhotoshoot(photoshoot._id)}
                      key={index}
                    />
                  )
                )
              ) : (
                <div style={{ display: "flex" }}>No photoshoot yet.</div>
              )
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spinner size="lg" color="primary" />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

ListPhotoshoot.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  addPhotoshoot: PropTypes.func.isRequired,
  listPhotoshootClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  logoutUser,
  addPhotoshoot,
  listPhotoshootClient,
  emptyCurrentPhotoshoot,
  getPhotoshoot,
})(ListPhotoshoot);
