import React from "react";
// reactstrap components
import { Card, Row, Col } from "reactstrap";
export default class RefundPolicy extends React.Component {
  render() {
    return (
      <>
        <Row style={{ justifyContent: "center" }}>
          <Col lg="6" style={{ padding: "50px", justifyContent: "center" }}>
            <Card
              className="justify-content-center"
              style={{ minHeight: "70%" }}
            >
              <Row>
                <Col lg="12" style={{ padding: "50px" }}>
                  <h1>30 Days Refund Policy</h1>
                  <p>
                    You can get a total refund up to 30 days after purchase of
                    your pictures. To get a total refund, you can follow the
                    instructions below.
                  </p>
                  <br />
                  You can contact our support{" "}
                  <a href="/dashboard/support">
                    <b>here</b>
                  </a>
                  .<p>Or</p>
                  <p>
                    You can contact us by email at{" "}
                    <a href="mailto:contact@yoov.io">
                      <b>contact@yoov.io</b>
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
