const COLORS = {
  accent_color: "#5E72E4",
  text_accent_color_alt: "#050505",
  text_black: "#000",
  text_black_secondary: "#272727",
  grey: "#939393",
  //accent_color: "#4881F0",
  //text_accent_color_alt: "#515A79",
  accent_secondary_color: "#F0EFEE",
  accent_secondary_color_text: "#A9A2A2",
  background_color: "#F7F8FA",
  background_color_secondary: "#fff",
  background_color_tertiary: "#fff",
  text_light: "#F9F8E4",
  text_light_secondary: "#8C8C8C",
  grey_1: "#F8F9FA",
  grey_2: "#DDE2E5",
  grey_3: "#ACB5BD",
  grey_4: "#495057",
  grey_5: "#212429",
};

const TEXT_CONSTANTS = {
  fontSizeText: 18,
};

const STYLES = {
  elevation0: {
    boxShadow: "0px 0px 0px rgba(0,0,0,0)",
  },
  elevation: {
    boxShadow: "5px 5px 20px rgba(0,0,0,0.05)",
  },
  elevation_high: {
    boxShadow: "20px 50px 50px rgba(0,0,0,0.1)",
  },
};
export { COLORS, TEXT_CONSTANTS, STYLES };
