import React from "react";
import ImageThumbnail from "../layout/ImageThumbnail";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { COLORS } from "../../style/Constants";
class GalleryPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures_grid: [], modal_pic_index: "", modal: false };
  }
  static getDerivedStateFromProps(props, state) {
    // Generating the nested array for the grid of pictures
    var nestedarray = [];
    var temp_col_array = [];
    /* 
    
    var NUMBER_PER_ROW = 6;
    var maxRows = 1;
    if (props.picturesList) {
      props.picturesList.map((element, index) => {
        if (index < NUMBER_PER_ROW * maxRows) {
          if (index % NUMBER_PER_ROW === 0 && index !== 0) {
            // we had the row
            nestedarray.push(temp_col_array);
            // we reset the row by adding only one col
            temp_col_array = [element];
          } else {
            // we had the column
            temp_col_array.push(element);
          }
        }
      });
    }*/

    if (temp_col_array !== []) {
      nestedarray.push(temp_col_array);
    }
    if (nestedarray !== state.pictures_grid) {
      return { pictures_grid: nestedarray };
    } else {
      return null;
    }
  }
  render() {
    // If locked, we only display the six first pictures, else we display everything
    const list_of_pictures_to_render = this.props.locked
      ? this.props.picturesList.slice(0, 6)
      : this.props.picturesList;

    var size_col = this.props.size_col || 4;
    return (
      <>
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={() => this.setState({ modal: !this.state.modal })}
          >
            <ModalHeader
              toggle={() => this.setState({ modal: !this.state.modal })}
            >
              Preview
            </ModalHeader>
            <ModalBody>
              <ImageThumbnail
                src={
                  this.props.locked
                    ? list_of_pictures_to_render[
                        this.state.modal_pic_index
                      ]?.replace("upload/", "upload/fl_tiled,l_asd45a_gopyso/")
                    : list_of_pictures_to_render[this.state.modal_pic_index]
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  if (this.state.modal_pic_index == 0) {
                    this.setState({
                      modal_pic_index: list_of_pictures_to_render.length - 1,
                    });
                  } else {
                    this.setState({
                      modal_pic_index: this.state.modal_pic_index - 1,
                    });
                  }
                }}
              >
                Prev
              </Button>{" "}
              <Button
                color="primary"
                onClick={() =>
                  this.setState({
                    modal_pic_index:
                      (this.state.modal_pic_index + 1) %
                      list_of_pictures_to_render.length,
                  })
                }
              >
                Next
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <Row style={{ paddingBottom: "10px" }}>
          {list_of_pictures_to_render.map((picture, index) => (
            <Col
              lg={size_col}
              md={size_col}
              xs={6}
              key={index}
              style={{ padding: "5px" }}
            >
              {this.props.select_mode && (
                <div
                  className="hoverable shadow"
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "#fff",
                    textAlign: "center",
                    position: "absolute",
                    borderRadius: 10,
                    right: -3,
                    top: -3,
                  }}
                  onClick={() => this.props.onSelect(index)}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      margin: 10,
                      backgroundColor: this.props.selected_indexes.includes(
                        index
                      )
                        ? COLORS.accent_color
                        : COLORS.grey_2,
                      borderRadius: 5,
                    }}
                  ></div>
                </div>
              )}
              <a
                href={
                  this.props.locked
                    ? picture.replace(
                        "upload/",
                        "upload/fl_tiled,l_asd45a_gopyso/"
                      )
                    : picture
                }
                onClick={(e) => {
                  if (this.props.locked) {
                    e.preventDefault();
                  } else {
                    e.preventDefault();
                    this.setState({ modal: true, modal_pic_index: index });
                  }
                }}
                download
              >
                <ImageThumbnail
                  src={
                    this.props.locked
                      ? picture.replace(
                          "upload/",
                          "upload/fl_tiled,l_asd45a_gopyso/"
                        )
                      : picture
                  }
                />
              </a>
            </Col>
          ))}
        </Row>
        {/* 
        {this.state.pictures_grid.map((row_pictures, indexrow) => (
          <Row key={indexrow} style={{ paddingBottom: "10px" }}>
            {row_pictures.map((col_picture, index) => (
              <Col md={4} xs={6} key={index}>
                <a
                  href={
                    this.props.locked
                      ? col_picture.replace(
                        "upload/",
                        "upload/fl_tiled,l_asd45a_gopyso/"
                      )
                      : col_picture
                  }
                  onClick={e => {
                    if (this.props.link_locked) {
                      e.preventDefault();
                    }
                  }}
                  download
                >
                  <ImageThumbnail
                    src={
                      this.props.locked
                        ? col_picture.replace(
                          "upload/",
                          "upload/fl_tiled,l_asd45a_gopyso/"
                        )
                        : col_picture
                    }
                  />
                </a>
              </Col>
            ))}
          </Row>
                  ))}*/}
      </>
    );
  }
}

export default GalleryPreview;
