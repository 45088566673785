import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";
import { computePrice, computePriceWithOptions } from "../../helpers/global";

class GetPrice extends React.Component {
  constructor(props) {
    super();
    this.state = {
      editingOffered: props.current_photoshoot?.editingOffered === "true",
    };
  }

  onChange = (e) => {
    this.setState({ editingOffered: e.target.value === "true" });
  };

  render() {
    const price = computePriceWithOptions(this.state.editingOffered);
    return (
      <Row className="justify-content-center">
        <Col lg="4" md="8" sm="12">
          <h5 style={{ marginBottom: "20px", fontWeight: 600 }}>
            Setting the price of the shoot
          </h5>

          <div
            className="custom-control custom-radio mb-3  withBackgroundGrey"
            style={{ paddingTop: "20px" }}
          >
            <input
              className="custom-control-input"
              id="customRadio5"
              name="custom-radio-2"
              type="radio"
              onChange={this.onChange}
              value={true}
            />
            <label className="custom-control-label" htmlFor="customRadio5">
              The client wants you to edit their pictures
            </label>
          </div>
          <div
            className="custom-control custom-radio mb-3  withBackgroundGrey"
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <input
              className="custom-control-input"
              id="customRadio6"
              onChange={this.onChange}
              name="custom-radio-2"
              type="radio"
              value={false}
            />
            <label className="custom-control-label" htmlFor="customRadio6">
              The client doesn't want any editing
            </label>
          </div>
          <div
            className="bg-secondary"
            style={{
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          >
            The price will be : <b>${price}</b>
          </div>
          <Button
            color="default"
            size="lg"
            style={{ width: "100%" }}
            onClick={() => this.props.callback(this.state.editingOffered)}
            disabled={this.state.editingOffered === ""}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    );
  }
}

GetPrice.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(GetPrice);
