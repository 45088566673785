import { SET_CURRENT_PHOTOSHOOT, SET_LIST_PHOTOSHOOT } from "../actions/types";

const initialState = {
  listphotoshoot: false,
  photoshoot: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LIST_PHOTOSHOOT:
      return { ...state, listphotoshoot: action.payload };
    case SET_CURRENT_PHOTOSHOOT:
      return { ...state, photoshoot: action.payload };
    default:
      return state;
  }
}
