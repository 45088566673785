import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import {
  FiCommand,
  FiVideo,
  FiUsers,
  FiChevronRight,
  FiUserCheck,
  FiZap,
  FiThumbsUp,
  FiRefreshCw,
} from "react-icons/fi";
import history from "../../history";
// core components
import LandingNavbar from "../../components/navbars_/LandingNavbar";
import CardsFooter from "../../components/footers/CardsFooter";
import { ROUTE_TO_REGISTER_PHOTOGRAPHER } from "../../routes/routesConstants";
import CarouselLanding from "../../components/layout/CarouselLanding";
import { COLORS, STYLES } from "../../style/Constants";

function CardPlan(props) {
  return (
    <Card>
      <CardBody className="py-4" style={{ textAlign: "center" }}>
        <h6 className="text-primary text-uppercase" style={{ fontWeight: 600 }}>
          {props.title}
        </h6>
        <h5 className="mt-3 text-black" style={{ marginBottom: 34 }}>
          {props.description}
        </h5>
        {props.features?.map((item) => (
          <>
            <hr class="dashed" style={{ width: 100 }}></hr>
            <p className="description mt-3 text-muted">{item}</p>
          </>
        ))}
        <Button
          className="btn-icon mb-3 mb-sm-0 bg-gradient-primary "
          color="white"
          style={{ border: "none", color: "#fff", marginTop: 34 }}
          onClick={() => history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)}
        >
          <span className="btn-inner--text ">Get started</span>
          <span className="btn-inner--icon mr-1">
            <FiChevronRight />
          </span>
        </Button>
      </CardBody>
    </Card>
  );
}
function CardStep(props) {
  return (
    <Card className="card-lift--hover  border-0">
      {props.tooltip && (
        <div
          style={{
            position: "absolute",
            top: -10,
            borderRadius: 100,
            right: -10,
            height: 40,
            width: 40,
            textAlign: "center",
            lineHeight: "40px",
            backgroundColor: COLORS.accent_color,
            color: "#fff",
          }}
        >
          {props.tooltip}
        </div>
      )}
      <CardBody className="py-5">
        <div
          className="icon icon-shape icon-shape-secondary  mb-4"
          style={{ backgroundColor: "#F0F2FD", borderRadius: 13 }}
        >
          {props.icon}
        </div>
        <h6 className="text-primary text-uppercase" style={{ fontWeight: 600 }}>
          {props.title}
        </h6>
        <p className="description mt-3">{props.description}</p>
      </CardBody>
    </Card>
  );
}

class LandingEasyShoot extends React.Component {
  state = {};
  componentDidMount() {
    /*document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;*/
  }
  render() {
    return (
      <>
        <LandingNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg pb-250">
              {/* <img
                className="d-block d-sm-none"
                src="images/illustrations/photographernature.png"
                alt="illustrationphotographer"
                style={{ width: "100%" }}
              />
              <img
                className="d-none d-sm-block"
                src="images/illustrations/photographernature.png"
                alt="illustrationphotographer"
                style={{ width: "100%", position: "absolute" }}
              />*/}
              <Container
                className="py-lg-md d-flex"
                style={{ marginTop: "150px" }}
              >
                <div className="col px-0">
                  <Row>
                    <Col lg="8" sm="8">
                      <h1 className="display-1 text-dark">
                        We help{" "}
                        <h1 className={"display-1 text-gradient"}>
                          photographers
                        </h1>
                        focus on the essential
                      </h1>
                      <h5
                        className="display-5 text-muted"
                        style={{ fontWeight: 500, paddingRight: 100 }}
                      >
                        With yoov, you just need to photograph. We will help you
                        handling the client's information, emailing, uploading
                        and receiving the payments.
                      </h5>
                      <div className="btn-wrapper" style={{ marginTop: 34 }}>
                        <Button
                          className="btn-icon btn-lg  mb-3 mb-sm-0 bg-gradient-primary "
                          color="white"
                          style={{ border: "none", color: "#fff" }}
                          onClick={() =>
                            history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-ic_add_48px" />
                          </span>
                          <span className="btn-inner--text ">
                            Get started ( 100% FREE )
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <h1
                    className="display-1 text-dark"
                    style={{ marginBottom: 100, textAlign: "center" }}
                  >
                    Easy To Setup
                  </h1>
                  <Row className="row-grid">
                    <Col lg="4">
                      <CardStep
                        tooltip="1"
                        title="Setup the photoshoot"
                        description="You enter the name and email of the client. You also indicate the price of the photoshoot."
                        icon={<FiCommand />}
                      />
                    </Col>
                    <Col lg="4">
                      <CardStep
                        tooltip="2"
                        title="Upload the pictures"
                        description="We will host the pictures for you. There is no limit in number or size. The pictures will be locked until the clients pay to unlock them."
                        icon={<FiVideo />}
                      />
                    </Col>
                    <Col lg="4">
                      <CardStep
                        tooltip="3"
                        title="Receive your payment"
                        description="We invite the clients to pay by email. Once they pay, you will receive their payments automatically."
                        icon={<FiUsers />}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>

            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg bg-white" id="howdoesitwork">
            <Container>
              <Card className="bg-dark elevation " style={{}}>
                <Row
                  className="justify-content-md-center"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  <Col
                    sm={12}
                    md={5}
                    style={{ paddingLeft: 80, paddingRight: 80 }}
                  >
                    <h1
                      className="display-2 text-white"
                      style={{ textAlign: "left", marginBottom: 21 }}
                    >
                      Truly Customer Friendly
                    </h1>
                    <p style={{}} className="text-muted">
                      We are continuously working on a smooth experience for
                      your customers, from their photoshoot to their payment.
                    </p>
                  </Col>
                  <Col
                    md={7}
                    sm={12}
                    style={{ paddingLeft: 80, paddingRight: 80 }}
                  >
                    <Row style={{ textAlign: "left" }}>
                      <Col md={6}>
                        <FiUserCheck
                          size={30}
                          className="text-primary"
                        ></FiUserCheck>
                        <h5 className="text-primary" style={{ marginTop: 20 }}>
                          Optimized Experience
                        </h5>
                        <p className="text-muted">We</p>
                      </Col>
                      <Col md={6}>
                        <FiZap size={30} className="text-primary"></FiZap>
                        <h5 className="text-primary" style={{ marginTop: 20 }}>
                          Quick Photoshoot
                        </h5>
                        <p className="text-muted">
                          A photoshoot lasts 5 minutes only.
                        </p>
                      </Col>
                      <Col md={6} style={{ marginTop: 34 }}>
                        <FiThumbsUp
                          size={30}
                          className="text-primary"
                        ></FiThumbsUp>
                        <h5 className="text-primary" style={{ marginTop: 20 }}>
                          Trained Photographers
                        </h5>
                        <p className="text-muted">
                          To ensure a great customer relationship.
                        </p>
                      </Col>
                      <Col md={6} style={{ marginTop: 34 }}>
                        <FiRefreshCw
                          size={30}
                          className="text-primary"
                        ></FiRefreshCw>
                        <h5 className="text-primary" style={{ marginTop: 20 }}>
                          Easy Refund
                        </h5>
                        <p className="text-muted">
                          To avoid any negative feedback.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Container>
          </section>

          <section
            className="section section-lg bg-gradient-primary "
            id="paymentinfo"
          >
            <Container className=" pb-20">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h1 className="display-1 text-white ">Completely Free</h1>
                  <p className="lead text-white">
                    We are open to any type of personalization, and here are
                    examples of solutions we provide.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <CardPlan
                    title="Easy"
                    description="Your customers pay for their pictures"
                    features={[
                      "You don't pay anything",
                      "You receive a 5% commission",
                    ]}
                  ></CardPlan>
                </Col>
                <Col lg="4">
                  <CardPlan
                    title="Flexible"
                    description="We help you to include Yoov in your options"
                    features={[
                      "Flexible with your business model",
                      "You control your price point",
                    ]}
                  ></CardPlan>
                </Col>
                <Col lg="4">
                  <CardPlan
                    title="Tailored"
                    description="You handle the photographers"
                    features={["Most flexible", "You control everything"]}
                  ></CardPlan>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg pt-lg-100 section-contact-us">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" sm="12">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h1 className="display-1" style={{ fontWeight: 800 }}>
                        Ready to make your <br /> business shine?
                      </h1>
                      <p className="mt-0">
                        For more information, we'll have an expert answering all
                        your questions.
                      </p>

                      <div>
                        <div>
                          <Button
                            className="btn-round"
                            color="default"
                            size="lg"
                            onClick={() =>
                              history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)
                            }
                            type="button"
                          >
                            Get started
                          </Button>
                          <Button
                            className="btn-round btn-outline-primary"
                            size="lg"
                            onClick={() =>
                              history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)
                            }
                            type="button"
                          >
                            Contact our sales
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default LandingEasyShoot;
