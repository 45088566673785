import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import store from "./../../../../store";
import { Col, Row, Spinner } from "reactstrap";
import {
  addPhotoshoot,
  editPhotoshootClient,
  getPhotoshoot,
  emptyCurrentPhotoshoot,
  uploadPictures,
} from "../../../../actions/photoshootActions";
import history from "../../../../history";
// reactstrap components
import { Card } from "reactstrap";

// core components
import WaitingForPictures from "../../../../components/photoshootClient/WaitingForPictures";
import BuyPictures from "../../../../components/photoshootClient/BuyPictures";
import PhotoshootFinished from "../../../../components/photoshootClient/PhotoshootFinished";
import CardPhotoshoot from "../../../../components/layout/CardPhotoshoot";
import { ROUTE_TO_HOME } from "../../../../routes/routesConstants";
class StepOrchestratorClient extends React.Component {
  constructor() {
    super();

    this.stepsList = [
      "getName",
      "shoot",
      "joinClient",
      "uploadPictures",
      "buyPictures",
      "paymentDone",
    ];
    this.stepsLogic = {
      getName: {
        component: WaitingForPictures,
        callback: () => this.finishLater(),
      },
      shoot: {
        component: WaitingForPictures,
        callback: () => this.finishLater(),
      },
      joinClient: {
        component: WaitingForPictures,
        callback: () => this.finishLater(),
      },
      uploadPictures: {
        component: WaitingForPictures,
        callback: () => this.finishLater(),
      },
      buyPictures: {
        component: BuyPictures,
        callback: () => this.goToStep("paymentDone"),
      },
      paymentDone: {
        component: PhotoshootFinished,
        callback: () => history.push(ROUTE_TO_HOME),
      },
    };

    this.state = {
      step: 0,
      nameclient: "",
      loading: true,
    };
  }
  componentDidMount() {
    store
      .dispatch(getPhotoshoot(this.props.match.params.photoshootid))
      .then(() => this.setState({ loading: false }));
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  increaseStep = () => {
    this.setState({ step: this.state.step + 1 });
  };
  goToStep = (stepname) => {
    this.props.editPhotoshootClient(this.props.photoshoot.photoshoot._id, {
      step: stepname,
    });
  };

  finishLater = () => {
    this.props.emptyCurrentPhotoshoot();
    history.push(ROUTE_TO_HOME);
  };
  binaryStep = (bool, stepIfTrue, stepIfFalse) => {
    if (bool === true) {
      this.goToStep(stepIfTrue);
    } else {
      this.goToStep(stepIfFalse);
    }
  };

  render() {
    if (!this.props.photoshoot.photoshoot && !this.state.loading) {
      console.log("error loading the photoshoot");
    }
    const current_photoshoot = this.props.photoshoot.photoshoot;
    let current_step = this.stepsList[0];

    if (this.stepsList.includes(current_photoshoot.step)) {
      current_step = current_photoshoot.step;
    }

    const StepComponent = this.stepsLogic[current_step].component;
    const stepCallback = this.stepsLogic[current_step].callback;
    const stepAction = this.stepsLogic[current_step].action;
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {!this.state.loading ? (
            <div style={{ minWidth: "90%" }}>
              {current_photoshoot.clientName && (
                <CardPhotoshoot photoshoot={current_photoshoot} />
              )}
              <Card
                style={{
                  paddingTop: "30px",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <StepComponent
                  callback={stepCallback}
                  current_photoshoot={current_photoshoot}
                  action={stepAction}
                />
              </Card>

              <div
                style={{ textAlign: "center", marginTop: "10px" }}
                onClick={() => this.finishLater()}
              >
                <p className="hoverable">Save for later</p>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Spinner size="lg" color="primary" />
            </div>
          )}
        </div>
      </>
    );
  }
}

StepOrchestratorClient.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  addPhotoshoot: PropTypes.func.isRequired,
  editPhotoshootClient: PropTypes.func.isRequired,
  uploadPictures: PropTypes.func.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  logoutUser,
  addPhotoshoot,
  editPhotoshootClient,
  emptyCurrentPhotoshoot,
  uploadPictures,
  getPhotoshoot,
})(StepOrchestratorClient);
