import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { GET_ALERTS } from "../../../actions/types";
import store from "../../../store";
import NotFound from "./../../errorPages/NotFound";
import axios from "axios";
// reactstrap components
import { Row, Col, Spinner } from "reactstrap";
import { emptyCurrentPhotoshoot } from "../../../actions/photoshootActions";
import history from "../../../history";
import {
  ROUTE_TO_HOME,
  ROUTE_TO_LOGOUT,
  ROUTE_TO_SUPPORT,
} from "../../../routes/routesConstants";
class HomePage extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    const code = params.get("code");
    console.log(code);
    if (code) {
      return this.onReceiveCode(code);
    } else {
      store.dispatch({
        type: GET_ALERTS,
        payload: {
          type: "error",
          message:
            "An error has occured when setting the earning account. Please try again or contact the support.",
        },
      });
      history.push(ROUTE_TO_HOME);
    }
  }

  onReceiveCode = (code) => {
    console.log(this.props);
    axios
      .post(
        process.env.REACT_APP_SERVER_URI +
          "/api/payments/connect/generateid/" +
          this.props.auth.user.id,
        {
          data: {
            code: code,
          },
        }
      )
      .then((result) => {
        store.dispatch({
          type: GET_ALERTS,
          payload: {
            type: "success",
            message: "Account updated with success ! Please reconnect.",
          },
        });
        history.push(ROUTE_TO_LOGOUT);
      });
  };

  render() {
    const { user } = this.props.auth;

    return (
      <>
        <section
          className="section"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {user.type && user.type.includes("photographer") ? (
            <div>
              <Row className="justify-content-center">
                <Col lg="12" style={{ textAlign: "center" }}>
                  <h3 className="display-3">
                    {" "}
                    Synchronizing your Stripe account...
                  </h3>
                  <p>
                    {" "}
                    It should not take more than a few seconds. If it takes too
                    long, please{" "}
                    <a href={ROUTE_TO_SUPPORT}>Contact the support</a>.
                  </p>
                  <Spinner size="lg" color="primary" />
                </Col>
              </Row>
            </div>
          ) : (
            <NotFound />
          )}
        </section>
      </>
    );
  }
}

HomePage.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, emptyCurrentPhotoshoot })(
  HomePage
);
