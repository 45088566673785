import React from "react";
// reactstrap components
import NavbarButton from "./NavbarButton";

import { Row, Col } from "reactstrap";
import history from "../../history";
import {
  ROUTE_TO_PHOTOSHOOT_LIST,
  ROUTE_TO_SUPPORT,
  ROUTE_TO_HOME,
  ROUTE_TO_PROFILE,
} from "../../routes/routesConstants";
export default class BottomNavbar extends React.Component {
  constructor() {
    super();

    this.state = {
      current_nav: "Home",
    };
    this.menusList = {
      Home: { iconclass: "ni ni-home-52-2", path: ROUTE_TO_HOME },
      Shoots: {
        iconclass: "ni ni-ic_format_list_bulleted_48px-2",
        path: ROUTE_TO_PHOTOSHOOT_LIST,
      },
      Support: {
        iconclass: "ni ni-chat-46-2",
        path: ROUTE_TO_SUPPORT,
      },
      Profile: { iconclass: "ni ni-single-01-2", path: ROUTE_TO_PROFILE },
    };
  }
  onclick(menu) {
    this.setState({ current_nav: menu });
    history.push(this.menusList[menu].path);
  }

  componentDidMount() {}
  render() {
    return (
      <>
        <Row
          className="justify-content-md-center"
          style={{
            height: "100%",
            display: "flex",
            backgroundColor: "#fff",
          }}
        >
          <Col
            sm={12}
            md={8}
            lg={6}
            style={{
              flexDirection: "row",
              padding: 0,
              display: "flex",
              alignContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {Object.keys(this.menusList).map((menu, index) => {
              return (
                <NavbarButton
                  key={index}
                  title={menu}
                  iconclass={this.menusList[menu].iconclass}
                  path={this.menusList[menu].path}
                  active={this.state.current_nav === menu}
                  onclick={() => this.onclick(menu)}
                />
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}
