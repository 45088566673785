import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { patchUser } from "../../../actions/authActions";
// reactstrap components
import { Button } from "reactstrap";
import history from "../../../history";
// reactstrap components
import { Row, Col, Card } from "reactstrap";
import { computePrice } from "../../../helpers/global";
import { ROUTE_TO_HOME } from "../../../routes/routesConstants";

class EditPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equipment: this.props.auth.user.equipment || "camera",
      editingOffered: this.props.auth.user.editingOffered || false,
      error: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }).then(
      this.setState({
        price: this.computePrice(
          this.state.equipment,
          this.state.editingOffered
        ),
      })
    );
  };

  onClick = (attribute, value) => {
    this.setState({ [attribute]: value });
    console.log(this.state);
  };
  onSubmit = () => {
    const userData = {
      id: this.props.auth.user.id,
      equipment: this.state.equipment,
    };
    // If also modified password
    this.props.patchUser(userData);
  };

  render() {
    var prices = computePrice(this.state.equipment, this.state.editingOffered);
    var price = prices[0];
    var net_earnings = prices[1];
    return (
      <div>
        <h4 style={{ fontWeight: "700" }}>Edit Equipment</h4>
        <h6 style={{ marginBottom: "10px" }}>
          {" "}
          Update your equipment here, the price will adapt automatically.
        </h6>
        <h5>
          <b>Select your equipment</b>
        </h5>
        <Row>
          <Col xs={6}>
            <Card
              className={
                this.state.equipment === "phone" && "border border-primary"
              }
              style={{
                padding: "15px",
              }}
              onClick={() => this.onClick("equipment", "phone")}
            >
              <b>iPhone</b>
            </Card>
          </Col>
          <Col xs={6}>
            <Card
              className={
                this.state.equipment === "camera" && "border border-primary"
              }
              style={{
                padding: "15px",
              }}
              onClick={() => this.onClick("equipment", "camera")}
            >
              <b> Professional Camera </b>
            </Card>
          </Col>
        </Row>
        {!this.props.auth.user.isPriceHidden && (
          <>
            <h5>
              <b>Preview price</b>
            </h5>
            <Row style={{ paddingBottom: "30px" }}>
              <Col xs={6}>
                <Card
                  className={
                    !this.state.editingOffered && "border border-primary"
                  }
                  style={{
                    padding: "15px",
                  }}
                  onClick={() => this.onClick("editingOffered", false)}
                >
                  <b>Photoshoot Only</b>
                </Card>
              </Col>
              <Col xs={6}>
                <Card
                  className={
                    this.state.editingOffered && "border border-primary"
                  }
                  style={{
                    padding: "15px",
                  }}
                  onClick={() => this.onClick("editingOffered", true)}
                >
                  <b> Photoshoot and Editing </b>
                </Card>
              </Col>
            </Row>
            <div
              className="bg-primary"
              style={{
                color: "#fff",
                marginBottom: "30px",
                borderRadius: "15px",
                padding: "15px",
              }}
            >
              Price per Photoshoot: <b>${price}</b>
              <br />
              Net earnings (90%): <b>${net_earnings}</b>
            </div>
          </>
        )}
        <Button onClick={() => this.onSubmit()} color="primary" type="submit">
          Save the equipment
        </Button>
      </div>
    );
  }
}

EditPrice.propTypes = {
  auth: PropTypes.object.isRequired,
  patchUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { patchUser })(EditPrice);
