const badges_per_step = {
  getName: <span class="badge badge-info">Uncomplete</span>,
  setPrice: <span class="badge badge-info">Uncomplete</span>,
  shoot: <span class="badge badge-info">Uncomplete</span>,
  joinClient: <span class="badge badge-info">Uncomplete</span>,
  uploadPictures: <span class="badge badge-info">Uncomplete</span>,
  buyPictures: <span class="badge badge-secondary">Complete</span>,
  paymentDone: <span class="badge badge-success">Succeeded</span>,
  default: "ni ni-settings-gear-63-2",
};

export { badges_per_step };
