import React, { useState } from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { COLORS } from "../../../../style/Constants";
import { Row, Col, Button, Spinner } from "reactstrap";

class CheckoutFormClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardValidated: false,
      loadingCard: false,
    };
  }
  render() {
    return (
      <>
        <div>
          <Frames
            config={{
              debug: true,
              publicKey: "pk_test_f8290fab-5058-4fbb-bc50-f5385181fb31",
              localization: {
                cardNumberPlaceholder: "Card number",
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: "CVV",
              },
              style: {
                base: {
                  fontSize: "17px",
                  border: "2px solid " + COLORS.grey_2,
                  marginTop: 10,
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  backgroundColor: "#fff",
                },
                focus: {
                  border: "2px solid " + COLORS.accent_color,
                },
                valid: {
                  color: "green",
                },
                invalid: {
                  color: "red",
                },
              },
            }}
            ready={() => {}}
            frameActivated={(e) => {}}
            frameFocus={(e) => {}}
            frameBlur={(e) => {}}
            frameValidationChanged={(e) => {}}
            paymentMethodChanged={(e) => {}}
            cardValidationChanged={(e) => {
              this.setState({ cardValidated: e.isValid });
            }}
            cardSubmitted={(e) => {
              this.setState({ loadingCard: true });
            }}
            cardTokenized={(e) => {
              alert("The card has been tokenized, sending to server");
              this.setState({ loadingCard: false });
            }}
            cardTokenizationFailed={(e) => {
              this.setState({ loadingCard: false });
            }}
          >
            <CardNumber />
            <Row style={{ marginTop: 20, padding: 0 }}>
              <Col>
                <ExpiryDate />
              </Col>
              <Col>
                <Cvv />
              </Col>
            </Row>
            <Button
              disabled={!this.state.cardValidated || this.state.loadingCard}
              color={"primary"}
              style={{ width: "100%", marginTop: 20 }}
              onClick={() => {
                Frames.submitCard();
              }}
            >
              {this.state.loadingCard ? (
                <Spinner size="sm"></Spinner>
              ) : (
                "PAY $" + this.props.total_due
              )}
            </Button>
          </Frames>
        </div>
      </>
    );
  }
}

export default CheckoutFormClient;
