import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
// reactstrap components
import { Row, Col } from "reactstrap";
import { emptyCurrentPhotoshoot } from "../../../../actions/photoshootActions";
import HomeAnnounce from "./HomeAnnounce";
class HomePage extends React.Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  render() {
    const { user } = this.props.auth;
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "8",
            flexDirection: "column",
            aligItems: "center",
          }}
        >
          <Row className="px-5" style={{ marginBottom: "10px" }}>
            <Col xs="12">
              <h3 style={{ fontWeight: 700, marginBottom: 34, paddingTop: 21 }}>
                Hello, <span className="text-primary">{user.name}</span>
              </h3>
            </Col>
            <Col xs="12">
              <HomeAnnounce />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

HomePage.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, emptyCurrentPhotoshoot })(
  HomePage
);
