import axios from "../axios";
import {
  SET_CURRENT_PHOTOSHOOT,
  SET_LIST_PHOTOSHOOT,
  GET_ALERTS,
} from "./types";

export const addPhotoshoot = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(process.env.REACT_APP_SERVER_URI + "/api/photoshoots/", {
      data: data,
    })
    .then((res) => {
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editPhotoshootPhotographer = (id, data) => (dispatch) => {
  axios
    .patch(
      process.env.REACT_APP_SERVER_URI + "/api/photoshoots/photographer/" + id,
      { data: data }
    )
    .then((res) => {
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });
      dispatch({
        type: GET_ALERTS,
        payload: { type: "success", message: "Photoshoot updated!" },
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const editPhotoshootClient = (id, data) => (dispatch) => {
  axios
    .patch(process.env.REACT_APP_SERVER_URI + "/api/photoshoots/client/" + id, {
      data: data,
    })
    .then((res) => {
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });

      dispatch({
        type: GET_ALERTS,
        payload: { type: "success", message: "Information saved!" },
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const emptyCurrentPhotoshoot = () => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PHOTOSHOOT,
    payload: {},
  });
};

export const listPhotoshoot = (page) => (dispatch) => {
  axios
    .get(
      process.env.REACT_APP_SERVER_URI +
        "/api/photoshoots/feed/photographer/" +
        page
    )
    .then((res) => {
      dispatch({
        type: SET_LIST_PHOTOSHOOT,
        payload: res.data.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const listPhotoshootClient = (page) => (dispatch) => {
  axios
    .get(
      process.env.REACT_APP_SERVER_URI + "/api/photoshoots/feed/client/" + page
    )
    .then((res) => {
      dispatch({
        type: SET_LIST_PHOTOSHOOT,
        payload: res.data.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPhotoshoot = (id) => (dispatch) => {
  return axios
    .get(process.env.REACT_APP_SERVER_URI + "/api/photoshoots/" + id)
    .then((res) => {
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const activateClient = (id, name, email, cb) => (dispatch) => {
  console.log(name);
  axios
    .post(
      process.env.REACT_APP_SERVER_URI +
        "/api/photoshoots/activateclient/" +
        id,
      {
        data: { name: name, email: email },
      }
    )
    .then((res) => {
      console.log(res);
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });
      cb();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const uploadPictures = (id, files) => (dispatch) => {
  const files_array = Array.from(files);
  const formData = new FormData();
  files_array.forEach((file, i) => {
    formData.append(i, file);
  });

  return axios
    .post(
      process.env.REACT_APP_SERVER_URI + "/api/photoshoots/upload-images/" + id,
      formData
    )
    .then((res) => {
      dispatch({
        type: SET_CURRENT_PHOTOSHOOT,
        payload: res.data.data,
      });

      dispatch({
        type: GET_ALERTS,
        payload: { type: "success", message: "Pictures uploaded!" },
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_ALERTS,
        payload: {
          type: "error",
          message: "Error uploading. Your session is expired.",
        },
      });
    });
};
