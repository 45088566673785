import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

class WaitingForPictures extends React.Component {
  constructor(props) {
    super();
    this.state = {
      code: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    return (
      <Row className="justify-content-center">
        <Col lg="12">
          <h5 style={{ marginBottom: "20px" }}>
            The photographer has not uploaded the pictures
          </h5>
          <p>
            It usually takes a few hours for the photographer to upload the
            pictures
          </p>
          <Button
            color="default"
            size="lg"
            style={{ width: "100%" }}
            onClick={() => this.props.callback(this.state.code)}
          >
            {" "}
            Go Back Home
          </Button>
        </Col>
      </Row>
    );
  }
}

WaitingForPictures.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(WaitingForPictures);
