import { SET_PUBLIC_PROFILE } from "../actions/types";

const initialState = {
  current_public_profile: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PUBLIC_PROFILE:
      return { ...state, current_public_profile: action.payload };
    default:
      return state;
  }
}
