import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "reactstrap";
import history from "../../history";
// core components
import LandingNavbar from "../../components/navbars_/LandingNavbar";
import CardsFooter from "../../components/footers/CardsFooter";
import { ROUTE_TO_REGISTER_PHOTOGRAPHER } from "../../routes/routesConstants";
import { COLORS, STYLES } from "../../style/Constants";
import Fade from "react-reveal/Fade";
const is_mobile = window.innerWidth < 768;
class LandingCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, location: "" };
  }
  componentDidMount() {
    /*document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;*/
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            You caught us too soon!
          </ModalHeader>
          <ModalBody>
            We are working to release publicly Yoov as soon as possible. Thanks
            for your interest.
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <main ref="main" style={{ background: "#fff" }}>
          {/* shape Hero */}
          <section className="section" style={{ padding: 0 }}>
            <div
              className="px-3"
              style={{
                background:
                  "url('images/illustrations/friends_on_the_beach_yoov.jpg') " +
                  COLORS.accent_color,
                backgroundPosition: "center",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                alt="yoov logo"
                className="crisp-edges"
                src={"/images/brand/logo_long_white_back.png"}
                style={{
                  height: 40,
                  position: "absolute",
                  top: 55,
                  margin: "0 auto",
                  left: 0,
                  right: 0,
                }}
              />
              <div style={{ alignSelf: "center", zIndex: 10, marginTop: -150 }}>
                <div style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      fontWeight: "800",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    WE CAPTURE YOUR MOMENTS
                  </h1>
                  <h3 style={{ color: "#fff", textAlign: "center" }}>
                    Find quick photoshoots around you
                  </h3>
                </div>
                <div
                  style={{ background: "#fff", borderRadius: 15 }}
                  className="px-4 py-1 elevation1 mt-5"
                >
                  <Row className="py-1 px-1">
                    <Col xs={6} lg={5}>
                      <FormGroup style={{ margin: 0 }}>
                        <Label
                          for="location"
                          style={{ marginBottom: 0, fontWeight: "700" }}
                        >
                          Where?
                        </Label>
                        <Input
                          style={{ border: "none", padding: 0, marginTop: -10 }}
                          type="location"
                          name="location"
                          id="location"
                          placeholder="Pier 49, San Francisco"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} lg={3}>
                      <FormGroup style={{ margin: 0 }}>
                        <Label
                          for="duration"
                          style={{ marginBottom: 0, fontWeight: "700" }}
                        >
                          How long?
                        </Label>
                        <Input
                          style={{ border: "none", padding: 0, marginTop: -10 }}
                          type="duration"
                          value="5 minutes"
                          name="duration"
                          id="duration"
                          placeholder="Pier 49, San Francisco"
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ padding: 0 }} xs={12} lg={4}>
                      <Button
                        style={{ width: "100%", height: "100%" }}
                        color="primary"
                        onClick={this.toggleModal}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default LandingCustomer;
