/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  NavbarBrand,
} from "reactstrap";

import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io5";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer
          className="footer-big bg-dark"
          style={{ paddingBottom: 20, paddingTop: 50 }}
        >
          <Container>
            <Row>
              <Col sm="12" md="4">
                <NavbarBrand to="/" tag={Link}>
                  <img
                    alt="yoov logo"
                    className="crisp-edges"
                    src={"/images/brand/logo_long_white_back.png"}
                  />
                </NavbarBrand>
              </Col>
            </Row>
            <Row className=" mb-6">
              <Col xs="6" md="4">
                <h5 class="mt-3 text-light" style={{ fontWeight: 700 }}>
                  Company
                </h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="/dashboard/support">Support</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="./terms-of-service">Terms of Service</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/refund-policy">Refund Policy</a>
                  </li>
                </ul>
              </Col>
              <Col xs="6" md="4">
                <h5 class="mt-3 text-light" style={{ fontWeight: 700 }}>
                  Photographers
                </h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/join-us">Work for us</a>
                  </li>
                </ul>
              </Col>
              <Col xs="6" md="4">
                <h5 class="mt-3 text-light" style={{ fontWeight: 700 }}>
                  Businesses
                </h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li>
                    <a href="/b2b">Work with us</a>
                  </li>
                  <li>
                    <a href="/b2b">Advertise with us</a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-md-between">
              <Col xs="6">
                <div className="copyright">
                  © {new Date().getFullYear()}{" "}
                  <a href="https://www.yoov.io">Yoov.io</a>.
                </div>
              </Col>
              <Col xs="6">
                <Nav className="nav-footer justify-content-end">
                  <NavItem>
                    <NavLink href="https://www.instagram.com/yoov_app/">
                      <IoLogoInstagram size={24} />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://www.facebook.com/Yoov-100494948081246">
                      <IoLogoFacebook size={24} />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
