import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, setAuthToken } from "../../../actions/authActions";
import { Alert } from "reactstrap";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  div,
  Row,
  Col,
} from "reactstrap";
import {
  ROUTE_TO_PROFILE,
  ROUTE_TO_HOME,
  ROUTE_TO_REGISTER,
  ROUTE_TO_SUPPORT,
} from "../../../routes/routesConstants";
import { COLORS, STYLES } from "../../../style/Constants";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to login with the tokens in the query parameters

    if (this.props.auth.isAuthenticated) {
      console.log("Already authenticated");
      this.props.history.push(ROUTE_TO_PROFILE);
    } else {
      // Handles if this is after 0auth
      let token = null;
      let refreshToken = null;
      let search = window.location.search;
      let params = new URLSearchParams(search);
      if (params) {
        // Retrieve the tokens from the query
        token = "Bearer " + params.get("token");
        refreshToken = params.get("refresh_token");
        if (token && refreshToken) {
          setAuthToken(token, refreshToken, () =>
            this.props.history.push(ROUTE_TO_HOME)
          );
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push(ROUTE_TO_PROFILE);
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors.data,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            aligItems: "center",
            justifyContent: "center",
          }}
        >
          <Row className="justify-content-center" style={{ padding: 34 }}>
            <Col xl="4" lg="8" md="8" sm="12">
              <Card style={{ border: "1px solid " + COLORS.grey_2 }}>
                <CardHeader
                  className="bg-white pb-5"
                  style={{ borderRadius: "15px 15px 0 0" }}
                >
                  <div className="text-muted text-center mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Row className="justify-content-center">
                      <Col xs="6" style={{ padding: 5 }}>
                        <Button
                          size="lg"
                          className="btn-default btn-icon"
                          style={{
                            backgroundColor: "#2851AD",
                            borderColor: "#fff",
                            float: "right",
                            ...STYLES.elevation0,
                          }}
                          href={
                            process.env.REACT_APP_SERVER_URI +
                            "/api/auth/facebook"
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              height={15}
                              width={15}
                              alt="..."
                              src={"/images/icons/common/facebook.svg"}
                            />
                          </span>
                          <span className="btn-inner--text">Facebook</span>
                        </Button>
                      </Col>
                      <Col xs="6" style={{ padding: 5 }}>
                        <Button
                          size="lg"
                          style={{
                            float: "left",
                            ...STYLES.elevation0,
                            borderWidth: 2,
                            borderColor: COLORS.grey_2,
                          }}
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          href={
                            process.env.REACT_APP_SERVER_URI +
                            "/api/auth/google"
                          }
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              height={20}
                              width={20}
                              alt="..."
                              src={"/images/icons/common/google.svg"}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  {errors && errors.error ? (
                    <Alert color="warning">{errors.error}</Alert>
                  ) : (
                    <div className="text-center text-muted mb-4">
                      <small>Or sign in with credentials</small>
                    </div>
                  )}
                  <Form role="form" noValidate onSubmit={this.onSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-email-84-2" />
                        </InputGroupText>
                        <Input
                          placeholder="Email"
                          type="email"
                          onChange={this.onChange}
                          value={this.state.email}
                          id="email"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-lock" />
                        </InputGroupText>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          onChange={this.onChange}
                          value={this.state.password}
                          id="password"
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="my-2" color="primary" type="submit">
                        Sign in <i className="fi-rr-arrow-right"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    href="#support"
                    className="text-light"
                    onClick={(e) => this.props.history.push(ROUTE_TO_SUPPORT)}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <Link to={ROUTE_TO_REGISTER}>
                    <small>Create new account</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
