export const computePriceWithOptions = (editing) => {
  return 10 + 5 * editing;
};

export const computePrice = (price) => {
  const total = price * (1 + parseFloat(process.env.REACT_APP_FEES));
  return [total, price];
};

export const is_mobile = window.innerWidth < 768;

export const mock_list_photoshoots = [
  {
    clientName: "Pierre",
    email: "email@a.com",
    step: "joinClient",
    price: "13",
    is_mock: true,
    location: "Eiffel Tower",
  },
  {
    clientName: "Emily",
    email: "email@a.com",
    step: "buyPictures",
    price: "13",
    is_mock: true,
    createdAt: Date.parse("03/10/2021"),
    location: "Venice Beach",
  },
  {
    clientName: "Jake",
    email: "email@a.com",
    step: "paymentDone",
    price: "13",
    is_mock: true,
    createdAt: Date.parse("01/10/2021"),
    location: "Hollywood Sign",
  },
  {
    clientName: "John",
    email: "email@a.com",
    step: "paymentDone",
    price: "13",
    is_mock: true,
    createdAt: Date.parse("01/10/2020"),
    location: "Empire State Building",
  },
];
