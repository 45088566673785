import React from "react";

import { connect } from "react-redux";

import { UncontrolledAlert, Col } from "reactstrap";
import store from "../../store";
import { GET_ALERTS } from "../../actions/types";
import { is_mobile } from "../../helpers/global";
class FlashAlert extends React.Component {
  constructor() {
    super();
    this.state = {
      showAlert: false,
      type: null,
      message: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.alert) {
      var message = "";
      var type = "";
      console.log("received new alert");
      console.log(nextProps);
      if (nextProps.alert) {
        type = nextProps.alert.type;
        message = nextProps.alert.message;
      }
      console.log(type);
      console.log(message);
      console.log(this.state.message);
      if (message !== "" && message !== this.state.message) {
        this.setState({
          showAlert: true,
          type: type,
          message: message,
        });
        setTimeout(() => {
          this.setState({
            showAlert: false,
            type: null,
            message: null,
          });
          store.dispatch({
            type: GET_ALERTS,
            payload: null,
          });
        }, 3000);
      }
    }
  }
  render() {
    return this.state.showAlert ? (
      <div
        style={{
          position: "fixed",
          zIndex: 1,
          right: 30,
          alignSelf: "flex-end",
          left: is_mobile && 30,
          top: 20,
        }}
      >
        <UncontrolledAlert
          color={
            { error: "danger", success: "success", invalid: "danger" }[
              this.state.type
            ]
          }
          style={{
            paddingRight: 50,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <span className="alert-inner--text">{this.state.message}</span>
        </UncontrolledAlert>
      </div>
    ) : (
      <div />
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  alert: state.alert,
});

export default connect(mapStateToProps)(FlashAlert);
