import React, { Component } from "react";
import { Router } from "react-router";
import ConnectedApp from "./ConnectedApp";
import HttpsRedirect from "react-https-redirect";
import { Switch, Route } from "react-router-dom";
import LandingPhotographers from "./views/landing/LandingPhotographers";
import LandingB2B from "./views/landing/LandingB2B";
import history from "./history";
import { Provider } from "react-redux";
import store from "./store";
import { StripeProvider } from "react-stripe-elements";
import {
  ROUTE_TO_CLIENT_PICTURES,
  ROUTE_TO_UPLOAD_PICTURES,
} from "./routes/routesConstants";
import BuyPicturesLanding from "./views/dashboard/client/photoshootProcess/BuyPicturesLanding";
import UploadPicturesLanding from "./views/dashboard/photographer/photoshoot/UploadPicturesLanding";
import LandingEasyShoot from "./views/landing/LandingEasyShoot";
import Terms from "./views/support/PrivacyPolicy";
import PrivacyPolicy from "./views/support/Terms";
import RefundPolicy from "./views/support/RefundPolicy";
import LandingCustomer from "./views/landing/LandingCustomer";
import PhotographerProfileLanding from "./views/landing/PhotographerProfileLanding";

// Check for token to keep user logged in
class App extends Component {
  render() {
    return (
      <HttpsRedirect>
        <Provider store={store}>
          <Router history={history}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API}>
              <Switch>
                <Route
                  path={ROUTE_TO_CLIENT_PICTURES + "/:photoshootid"}
                  component={BuyPicturesLanding}
                ></Route>

                <Route
                  path={ROUTE_TO_UPLOAD_PICTURES + "/:photoshootid/:jwtToken"}
                  component={UploadPicturesLanding}
                ></Route>
                <Route path="/dashboard" component={ConnectedApp}></Route>
                <Route path="/b2b" component={LandingB2B}></Route>
                <Route
                  path="/yoover/:photographerid"
                  component={PhotographerProfileLanding}
                ></Route>
                <Route path="/terms-of-service" component={Terms}></Route>
                <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
                <Route path="/refund-policy" component={RefundPolicy}></Route>
                <Route path="/ez" component={LandingEasyShoot}></Route>
                <Route path="/join-us" component={LandingPhotographers}></Route>
                <Route component={LandingCustomer}></Route>
              </Switch>
            </StripeProvider>
          </Router>
        </Provider>
      </HttpsRedirect>
    );
  }
}
export default App;
