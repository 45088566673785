import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser, patchUser } from "../../../actions/authActions";
// reactstrap components
import {
  Button,
  Card,
  Row,
  Col,
  NavItem,
  Nav,
  NavLink,
  Label,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import store from "./../../../store";

import { GET_ALERTS } from "./../../../actions/types";
import { ROUTE_TO_PROFILE } from "../../../routes/routesConstants";
// reactstrap components
import { FormGroup, Form, Input, InputGroupText, InputGroup } from "reactstrap";
import { COLORS } from "../../../style/Constants";
import { matchPath, Route, Switch } from "react-router";
import EditProfile from "./EditProfile";
import {
  ROUTE_TO_EDIT_LOCATION,
  ROUTE_TO_EDIT_PRICE,
  ROUTE_TO_EDIT_PROFILE,
} from "../../../routes/routesProfile";
import PrivateRoute from "../../../components/private-route/PrivateRoute";
import Profile from "./Profile";
import history from "../../../history";
import EditPrice from "./EditPrice";
import EditLocation from "./EditLocation";

class CustomNavButton extends React.Component {
  render() {
    const isActive = matchPath(
      history.location.pathname,
      this.props.route
    )?.isExact;
    return (
      <NavItem>
        <NavLink
          href="#"
          style={{
            fontWeight: isActive ? 700 : 500,
          }}
          onClick={() => history.push(this.props.route)}
        >
          {this.props.icon && (
            <i className={"text-muted fi-rr-" + this.props.icon}></i>
          )}
          {"  "}
          {this.props.name}
        </NavLink>
      </NavItem>
    );
  }
}
const is_mobile = window.innerWidth < 768;
class CustomNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }
  render() {
    return is_mobile ? (
      <UncontrolledDropdown
        style={{ width: "100%", marginBottom: 34 }}
        isOpen={this.state.dropdownOpen}
        toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
      >
        <DropdownToggle
          id="dropdownMenuButton"
          caret
          color="white"
          style={{
            width: "100%",
            background: "#fff",
            boxShadow: "none",
            border: "1px solid " + COLORS.grey_3,
          }}
        >
          Profile Overview
        </DropdownToggle>
        <DropdownMenu
          aria-labelledby="dropdownMenuButton"
          style={{ width: "100%" }}
        >
          {this.props.items.map((item, index) => (
            <DropdownItem
              onClick={() =>
                item.route
                  ? history.push(item.route)
                  : item.onClick
                  ? item.onClick()
                  : null
              }
            >
              {item.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    ) : (
      <div>
        <Nav vertical>
          {this.props.items.map((item, index) =>
            item.route ? (
              <CustomNavButton
                route={item.route}
                name={item.name}
                icon={item.icon}
              />
            ) : item.onClick ? (
              <NavItem>
                <NavLink
                  href="#"
                  style={{ color: item.name == "Logout" && COLORS.grey_3 }}
                  onClick={() => item.onClick()}
                >
                  {item.icon && <i className={"fi-rr-" + item.icon}></i>}{" "}
                  {item.name}
                </NavLink>
              </NavItem>
            ) : null
          )}
        </Nav>
      </div>
    );
  }
}
class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var user = this.props.auth.user;

    const nav_items = [
      {
        route: ROUTE_TO_PROFILE,
        name: "Profile Overview",
        icon: "apps",
      },
      {
        route: ROUTE_TO_EDIT_PROFILE,
        name: "Account Settings",
        icon: "settings",
      },
      {
        name: "Logout",
        onClick: () => store.dispatch(logoutUser()),
        icon: "power",
      },
    ];
    return (
      <>
        <Row style={{ background: "#fff", flex: 1, justifyContent: "center" }}>
          <Col
            xs={12}
            sm={8}
            md={8}
            style={{
              padding: 34,
            }}
          >
            <Row>
              <Col xs={12}>
                <div style={{ paddingLeft: 70, marginBottom: 21 }}>
                  <h5 style={{ fontWeight: 600 }}>
                    <span
                      className="hoverable text-primary"
                      onClick={() => this.props.history.push(ROUTE_TO_PROFILE)}
                    >
                      <img
                        alt="..."
                        className="rounded-circle"
                        style={{
                          height: "50px",
                          width: "50px",
                          marginRight: "10px",
                          position: "absolute",
                          left: 20,
                        }}
                        src={
                          user.profilePicture
                            ? user.profilePicture
                            : "/images/icons/common/blank_user.svg"
                        }
                      />

                      {user.name}
                    </span>{" "}
                    / Account Settings
                  </h5>
                  <p style={{ marginTop: -10 }} className="text-muted">
                    Setup your Yoov account and edit your profile
                  </p>
                </div>
              </Col>
              <Col md={12} lg={3}>
                <CustomNav items={nav_items} />
              </Col>
              <Col md={12} lg={9}>
                <div className="mt-2 py-5text-center">
                  <Switch>
                    <PrivateRoute
                      exact
                      path={ROUTE_TO_EDIT_PROFILE}
                      component={EditProfile}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path={ROUTE_TO_EDIT_PRICE}
                      component={EditPrice}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path={ROUTE_TO_EDIT_LOCATION}
                      component={EditLocation}
                    ></PrivateRoute>
                    <PrivateRoute
                      exact
                      path={""}
                      component={Profile}
                    ></PrivateRoute>
                  </Switch>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

ProfileContainer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileContainer);
