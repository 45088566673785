import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Row, Col } from "reactstrap";
import Moment from "moment";
class CardPhotoshoot extends React.Component {
  render() {
    const icons_per_step = {
      getName: "ni ni-single-01-2",
      setPrice: "ni ni-settings-gear-63-2",
      shoot: "ni ni-camera-20",
      joinClient: "ni ni-ic_person_add_48px",
      uploadPictures: "ni ni-ic_file_download_48px",
      buyPictures: "ni ni-ic_hourglass_empty_48px",
      paymentDone: "ni ni-cart-simple",
      default: "ni ni-settings-gear-63-2"
    };
    return (
      <Card
        className="hoverable"
        onClick={() => this.props.onClick && this.props.onClick()}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "20px",
          paddingBottom: "20px",
          marginBottom: "20px"
        }}
      >
        <Row>
          <Col xs={this.props.photoshoot.step === "paymentDone" ? "9" : "10"}>
            <p
              style={{
                lineHeight: "50px",
                marginBottom: 0
              }}
            >
              <span
                style={{
                  color: "#ACACAC",
                  fontSize: "10px",
                  position: "absolute",
                  top: "-20px"
                }}
              >
                {Moment(this.props.photoshoot.createdAt).calendar()}
              </span>
              <span style={{ fontWeight: "bold" }}>
                {this.props.photoshoot.clientName}
              </span>
              ,{" "}
              {this.props.photoshoot.location &&
                this.props.photoshoot.location.split(",")[0]}
            </p>
          </Col>
          <Col xs={this.props.photoshoot.step === "paymentDone" ? "3" : "2"}>
            {this.props.photoshoot.step === "paymentDone" ? (
              <div
                className="bg-primary"
                style={{
                  position: "absolute",
                  right: 0,
                  textAlign: "center",
                  width: "100%",
                  height: "100%",
                  marginTop: "-20px",
                  paddingTop: "30px",
                  lineHeight: "100%",
                  paddingBottom: "60px",
                  marginRight: "-5px",
                  borderRadius: "0px 15px 15px 0px"
                }}
              >
                <h4
                  className={"display-3 text-white"}
                  style={{ marginTop: "-10px" }}
                >
                  {!this.props.auth.user.isPriceHidden &&
                    "$" + this.props.photoshoot.price}
                </h4>
              </div>
            ) : (
              <div
                className={"bg-primary"}
                style={{
                  float: "right",
                  width: "50px",
                  height: "50px",
                  textAlign: "center",
                  borderRadius: "50%",
                  verticalAlign: "middle"
                }}
              >
                <p
                  style={{
                    lineHeight: "50px"
                  }}
                >
                  <span style={{ color: "#fff" }}>
                    <i
                      className={
                        icons_per_step[this.props.photoshoot.step] ||
                        icons_per_step["default"]
                      }
                    ></i>
                  </span>
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}

CardPhotoshoot.propTypes = {
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth
});

export default connect(mapStateToProps)(CardPhotoshoot);
