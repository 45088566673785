const uri_dashboard = process.env.REACT_APP_DASHBOARD_URI;
export const ROUTE_TO_LOGIN = uri_dashboard + "/login";
export const ROUTE_TO_REGISTER = uri_dashboard + "/register";
export const ROUTE_TO_REGISTER_PHOTOGRAPHER =
  uri_dashboard + "/registerphotographer";
export const ROUTE_TO_REGISTER_BETA = uri_dashboard + "/registerbeta";
export const ROUTE_TO_HOME = uri_dashboard + "/";
export const ROUTE_TO_LOGOUT = uri_dashboard + "/logout";
export const ROUTE_TO_RECEIVE_TOKEN = uri_dashboard + "/receive-token";
export const ROUTE_TO_ONBOARD_CLIENT = uri_dashboard + "/on-board-client"; // /:email/:name
export const ROUTE_TO_PROFILE = uri_dashboard + "/profile";
export const ROUTE_TO_PHOTOSHOOT_PROCESS_PHOTOGRAPHER =
  uri_dashboard + "/photoshoot/process"; // /:photoshootid
export const ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT =
  uri_dashboard + "/photoshoot/process/client"; // /:photoshootid
export const ROUTE_TO_SUPPORT = uri_dashboard + "/support";
export const ROUTE_TO_ONBOARD_STRIPE = uri_dashboard + "/onboarding-stripe";
export const ROUTE_TO_PHOTOSHOOT_LIST = uri_dashboard + "/photoshoot/list/";
export const ROUTE_TO_NOT_ALLOWED = uri_dashboard + "/notpermission";
export const ROUTE_TO_VERIFY_EMAIL = uri_dashboard + "/verify-email"; // /:email/:code
export const ROUTE_TO_CLIENT_PICTURES = "/pictures"; // /:email/:name/:photoshootid
export const ROUTE_TO_UPLOAD_PICTURES = "/upload-pictures"; // :photoshootid/:jwtToken
