import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

class PhotoshootFinished extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <Row className="justify-content-center">
        <Col lg="12">
          {this.props.auth.user.isPriceHidden ? (
            <div>
              <h5 style={{ marginBottom: "20px" }}>Photoshoot done</h5>
              <p>
                {this.props.current_photoshoot.clientName} paid for your
                pictures !
              </p>
            </div>
          ) : (
            <div>
              <h5 style={{ marginBottom: "20px" }}>Payment Received</h5>
              <p>
                {this.props.current_photoshoot.clientName} sent you $
                {this.props.current_photoshoot.price} for the photoshoot.
              </p>
            </div>
          )}
          <Button
            color="default"
            size="lg"
            style={{ width: "100%" }}
            onClick={() => this.props.callback()}
          >
            {" "}
            Go Home
          </Button>
        </Col>
      </Row>
    );
  }
}

PhotoshootFinished.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(PhotoshootFinished);
