import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../../actions/authActions";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Spinner,
  Col,
} from "reactstrap";
import {
  ROUTE_TO_LOGIN,
  ROUTE_TO_PROFILE,
} from "../../../routes/routesConstants";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(ROUTE_TO_PROFILE);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.alert &&
      nextProps.alert.type &&
      (nextProps.alert.type === "error" || nextProps.alert.type === "invalid")
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
    };

    this.props.registerUser(newUser, this.props.type, this.props.history);
  };

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            aligItems: "center",
            justifyContent: "center",
          }}
        >
          {this.props.type === "photographer" && (
            <div style={{ textAlign: "center" }}>
              <h3 className="script-title">Become a Yoover now!</h3>
            </div>
          )}
          <Row className="justify-content-center">
            <Col xl="6" lg="8" md="8" sm="12" style={{ padding: 34 }}>
              <Card className="bg-white border-0">
                {this.props.type === "client" && (
                  <CardHeader className="bg-white pb-5">
                    <div className="text-muted text-center mb-3">
                      <small>Sign up with</small>
                    </div>
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col xs="6">
                          <Button
                            size="sm"
                            className="btn-neutral btn-icon"
                            color="default"
                            style={{ float: "right" }}
                            href={
                              process.env.REACT_APP_SERVER_URI +
                              "/api/auth/facebook"
                            }
                          >
                            <span className="btn-inner--icon mr-1">
                              <img
                                alt="..."
                                src={"/images/icons/common/facebook.svg"}
                              />
                            </span>
                            <span className="btn-inner--text">Facebook</span>
                          </Button>
                        </Col>
                        <Col xs="6">
                          <Button
                            size="sm"
                            style={{ float: "left" }}
                            className="btn-neutral btn-icon ml-1"
                            color="default"
                            href={
                              process.env.REACT_APP_SERVER_URI +
                              "/api/auth/google"
                            }
                          >
                            <span className="btn-inner--icon mr-1">
                              <img
                                alt="..."
                                src={"/images/icons/common/google.svg"}
                              />
                            </span>
                            <span className="btn-inner--text">Google</span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardHeader>
                )}
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Sign up with credentials</small>
                  </div>
                  <Form role="form" noValidate onSubmit={this.onSubmit}>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupText>
                          <i className="ni ni-single-01-2" />
                        </InputGroupText>
                        <Input
                          placeholder="Firstname"
                          type="text"
                          onChange={this.onChange}
                          value={this.state.name}
                          id="name"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupText>
                          <i className="ni ni-email-84-2" />
                        </InputGroupText>
                        <Input
                          placeholder="Email"
                          type="email"
                          onChange={this.onChange}
                          value={this.state.email}
                          id="email"
                        ></Input>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-lock" />
                        </InputGroupText>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          onChange={this.onChange}
                          value={this.state.password}
                          id="password"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-lock" />
                        </InputGroupText>
                        <Input
                          placeholder="Confirm Password"
                          onChange={this.onChange}
                          value={this.state.password2}
                          id="password2"
                          type="password"
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span>I agree with the Privacy Policy</span>
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="mt-4"
                        color="primary"
                        type="submit"
                        disabled={this.state.loading}
                      >
                        {this.state.loading && (
                          <Spinner size="sm" color="white" />
                        )}
                        Create account
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6"></Col>
                <Col className="text-right" xs="6">
                  <Link to={ROUTE_TO_LOGIN}>
                    <small>Already an account ?</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alert: state.alert,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
