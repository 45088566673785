import React from "react";
import TimelineCardPhotoshoot from "../../../../components/layout/TimelineCardPhotoshoot";
class Timeline extends React.Component {
  render() {
    console.log(this.props.listphotoshoot);
    return (
      <>
        {this.props.listphotoshoot.map((photoshoot, index) => (
          <TimelineCardPhotoshoot
            onClickPhotoshoot={this.props.onClickPhotoshoot}
            key={index}
            photoshoot={photoshoot}
          ></TimelineCardPhotoshoot>
        ))}
      </>
    );
  }
}

export default Timeline;
