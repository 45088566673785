import React from "react";

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import history from "../../history";
// core components
import LandingNavbar from "../../components/navbars_/LandingNavbar";
import CardsFooter from "../../components/footers/CardsFooter";
import { ROUTE_TO_REGISTER_PHOTOGRAPHER } from "../../routes/routesConstants";
import { COLORS, STYLES } from "../../style/Constants";
import Fade from "react-reveal/Fade";
const is_mobile = window.innerWidth < 768;
class LandingPhotographers extends React.Component {
  state = {};
  componentDidMount() {
    /*document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;*/
  }
  render() {
    return (
      <>
        <LandingNavbar />
        <main ref="main" style={{ background: "#fff" }}>
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section pb-250">
              <Container
                className="py-lg-md d-flex"
                style={{ marginTop: is_mobile ? 10 : 150 }}
              >
                <div className="col px-0">
                  <Row style={{ flexDirection: is_mobile && "row-reverse" }}>
                    {is_mobile && (
                      <Col md="7" sm="12">
                        <img
                          className=" crisp-edges"
                          src="images/illustrations/landing_picture_photographer.jpg"
                          alt="illustrationphotographer"
                          style={{
                            width: "100%",
                            position: "relative",
                            marginBottom: 20,
                          }}
                        />
                      </Col>
                    )}
                    <Col
                      md="5"
                      sm="12"
                      className={"d-flex d-md-inline"}
                      style={{ flexDirection: "column", alignItems: "center" }}
                    >
                      <h1
                        className="display-1 text-dark"
                        style={{
                          fontWeight: "800",
                          width: 350,
                          textAlign: is_mobile && "center",
                        }}
                      >
                        GET PAID TO PHOTOGRAPH
                      </h1>
                      <p
                        className="lead text-dark"
                        style={{ width: 250, textAlign: is_mobile && "center" }}
                      >
                        Anyone with a camera can make up to $120/hour.
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="primary"
                          size="large"
                          style={{
                            border: "none",
                            boxShadow: "none",
                            borderRadius: 200,
                            padding: 15,
                            paddingLeft: 34,
                            paddingRight: 34,
                          }}
                          onClick={() =>
                            history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)
                          }
                        >
                          <span className="btn-inner--text">Get Started</span>
                        </Button>
                      </div>
                    </Col>
                    {!is_mobile && (
                      <Col md="7" sm="12">
                        <img
                          className=" crisp-edges"
                          src="images/illustrations/landing_picture_photographer.jpg"
                          alt="illustrationphotographer"
                          style={{ width: "100%", position: "relative" }}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
            </section>
            {/* 1st Hero Variation */}
          </div>
          <Fade>
            <section className="section section-lg pt-lg-0">
              <Container>
                <Row className="justify-content-center align-items-center">
                  <Col
                    md="6"
                    className="justify-content-end"
                    style={{ zIndex: 1 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -50,
                        right: -100,
                        width: 200,
                        height: 200,
                        background: "#ECEDFF",
                        borderRadius: 21,
                      }}
                    ></div>
                    <img
                      src="images/illustrations/photographer_talking.jpeg"
                      alt="illustrationphotographer"
                      style={{
                        position: "relative",
                        width: 350,
                        borderRadius: 15,
                        float: is_mobile ? "left" : "right",
                        maxWidth: window.innerWidth - 60,
                        margin: "auto",
                      }}
                    ></img>
                    <div
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        bottom: 20,

                        right: is_mobile ? -20 : -200,
                        backgroundColor: "#fff",
                        padding: 13,
                        paddingLeft: 70,
                        paddingRight: 60,
                        borderRadius: 13,
                        ...STYLES.elevation_high,
                      }}
                    >
                      <img
                        style={{
                          float: "left",
                          width: 40,
                          position: "absolute",
                          left: 20,
                          top: 0,
                          bottom: 0,
                          margin: "auto",
                        }}
                        alt="..."
                        src={"/images/icons/common/location.svg"}
                      />

                      <p className="mb--2">Los Angeles</p>
                      <h5
                        style={{
                          fontWeight: "700",
                          color: COLORS.accent_color,
                        }}
                      >
                        Venice Beach
                      </h5>
                    </div>
                  </Col>
                  <Col md="6" className="mt--100">
                    <h2 style={{ fontWeight: "800", marginTop: 20 }}>
                      Grab your camera
                    </h2>
                    <h6 className="mt--2" style={{ fontWeight: "700" }}>
                      And ask tourists for 5 minute photoshoots
                    </h6>
                  </Col>
                </Row>
              </Container>
            </section>
          </Fade>
          <Fade>
            <section className="section section-lg pt-lg-50">
              <Container>
                <Row
                  className="justify-content-center align-items-center"
                  style={{ flexDirection: is_mobile && "column-reverse" }}
                >
                  <Col md="6" className="mt--100">
                    <h2 style={{ fontWeight: "800", marginTop: 20 }}>
                      Take great pictures
                    </h2>
                    <h6 className="mt--2" style={{ fontWeight: "700" }}>
                      And upload them on our app
                    </h6>
                  </Col>
                  <Col
                    md="6"
                    className="justify-content-end"
                    style={{ zIndex: 1 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: -50,
                        left: -100,
                        width: 200,
                        height: 200,
                        background: "#FEF3EF",
                        borderRadius: 500,
                      }}
                    ></div>
                    <img
                      src="images/illustrations/streetphotograph.jpeg"
                      alt="illustrationphotographer"
                      style={{
                        position: "relative",
                        width: 350,
                        borderRadius: 15,
                        float: "left",
                        maxWidth: window.innerWidth - 60,
                      }}
                    />
                    <div
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        bottom: 20,
                        left: is_mobile ? -20 : -230,
                        backgroundColor: "#fff",
                        padding: 13,
                        paddingLeft: 110,
                        paddingRight: 60,
                        borderRadius: 13,
                        ...STYLES.elevation_high,
                      }}
                    >
                      <img
                        style={{
                          float: "left",
                          width: 60,
                          position: "absolute",
                          left: 20,
                          top: 0,
                          bottom: 0,
                          margin: "auto",
                        }}
                        alt="..."
                        src={"/images/icons/common/stopwatch.svg"}
                      />

                      <p className="" style={{ fontWeight: "700" }}>
                        Easy process
                      </p>
                      <p style={{ fontSize: 15 }}>
                        Our app makes it easy
                        <br /> and fast to handle the <br /> shoot.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </Fade>
          <Fade>
            <section className="section section-lg pt-lg-50">
              <Container>
                <Row className="justify-content-center align-items-center">
                  <Col
                    md="6"
                    className="justify-content-end"
                    style={{ zIndex: 1 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: -50,
                        left: -100,
                        width: 200,
                        height: 200,
                        background: "#E5FFE8",
                        borderRadius: 50,
                      }}
                    ></div>
                    <img
                      src="images/illustrations/happy.jpeg"
                      alt="illustrationphotographer"
                      style={{
                        position: "relative",
                        width: 500,
                        borderRadius: 15,
                        float: "right",
                        maxWidth: window.innerWidth - 60,
                      }}
                    />
                    <div
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        marginTop: 70,
                        right: is_mobile ? -60 : -230,
                        backgroundColor: "#fff",
                        padding: 13,
                        bottom: 20,
                        paddingLeft: 110,
                        paddingRight: 60,
                        borderRadius: 13,
                        ...STYLES.elevation_high,
                      }}
                    >
                      <img
                        style={{
                          float: "left",
                          width: 60,
                          position: "absolute",
                          left: 20,
                          top: 0,
                          bottom: 0,
                          margin: "auto",
                        }}
                        alt="..."
                        src={"/images/icons/common/money-bag.svg"}
                      />

                      <p style={{ fontSize: 15, width: 200 }}>
                        You will receive money every time a customer pays to
                        unlock their pics
                      </p>
                    </div>
                  </Col>
                  <Col md="6" className="mt--100" style={{ zIndex: 5 }}>
                    <h2 style={{ fontWeight: "800", marginTop: 20 }}>
                      Get Paid
                    </h2>
                    <h6 className="mt--2" style={{ fontWeight: "700" }}>
                      And start enjoying the flexibility of your new job
                    </h6>
                  </Col>
                </Row>
              </Container>
            </section>
          </Fade>
          <Fade>
            <section
              className="section section-lg bg-light"
              style={{}}
              id="paymentinfo"
            >
              <Container className="pt-lg pb-20">
                <Row className="text-left justify-content-center">
                  <Col lg="12">
                    <h2
                      className="display-3 text-black"
                      style={{ fontWeight: "800" }}
                    >
                      Earn up to
                    </h2>
                    <h1
                      className="display-1 text-primary"
                      style={{ fontWeight: "800" }}
                    >
                      $120 per hour
                    </h1>
                  </Col>
                </Row>
                <Row className="row-grid mt-5">
                  <Col md={4}>
                    <div
                      style={{
                        background: "rgba(255,255,255,0.4)",
                        padding: 21,
                        borderRadius: 21,
                      }}
                    >
                      <p style={{ fontWeight: "700" }}>Be your own boss</p>
                      <p>
                        You work whenever you want. No minimum or maximum hours.
                      </p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div
                      style={{
                        background: "rgba(255,255,255,0.4)",
                        padding: 21,
                        borderRadius: 21,
                      }}
                    >
                      <p style={{ fontWeight: "700" }}>No requirement</p>
                      <p>
                        You don’t need prior knowledge, diplomas or experience.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
              <p
                style={{
                  paddingTop: 100,
                  fontSize: 10,
                  paddingLeft: 100,
                  paddingRight: 100,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                These values correspond to an average in the United States of
                what you should expect. This will largely depend on your country
                and location.
              </p>
            </section>
          </Fade>
          <section className="section section-lg section-contact-us bg-dark">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="12" sm="12">
                  <Card
                    className="bg-white shadow"
                    style={{
                      marginTop: -150,
                      paddingTop: 30,
                      paddingBottom: 30,
                      alignItems: "center",
                    }}
                  >
                    <CardBody className="p-lg-5">
                      <h4
                        className="mb-1 display-1"
                        style={{
                          fontWeight: "800",
                          textAlign: "center",
                        }}
                      >
                        Want to work with us?
                      </h4>
                      <p
                        className="mt-2 "
                        style={{
                          textAlign: "center",
                          marginBottom: 30,
                        }}
                      >
                        Creating an account is free and only takes 5 minutes.
                      </p>

                      <div
                        style={{
                          margin: "auto",
                          justifyContent: "center",
                          width: "100%",
                          display: !is_mobile && "flex",
                        }}
                      >
                        <Button
                          block={is_mobile}
                          className="btn-round"
                          color="primary"
                          size="lg"
                          onClick={() =>
                            history.push(ROUTE_TO_REGISTER_PHOTOGRAPHER)
                          }
                          style={{
                            border: "none",
                            boxShadow: "none",
                            borderRadius: 200,
                            paddingLeft: 34,
                            paddingRight: 34,
                          }}
                          type="button"
                        >
                          Create an account now
                        </Button>
                        <Button
                          block={is_mobile}
                          className="btn-round"
                          color="secondary"
                          style={{
                            background: "#F0F0F0",
                            border: "none",
                            boxShadow: "none",
                            borderRadius: 200,
                            paddingLeft: 34,
                            paddingRight: 34,
                            marginTop: is_mobile && 13,
                          }}
                          size="lg"
                          href="https://m.me/100494948081246"
                          type="button"
                          target={"_blank"}
                        >
                          <img
                            style={{
                              float: "left",
                              width: 20,
                              margin: "auto",
                              marginRight: 10,
                            }}
                            alt="..."
                            src={"/images/icons/common/chat.svg"}
                          />
                          CHAT WITH US
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default LandingPhotographers;
