import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import history from "../../../../history";
import { listPhotoshootClient } from "../../../../actions/photoshootActions";
// reactstrap componentsimport { emptyCurrentPhotoshoot } from "../../actions/photoshootActions";
import { Spinner } from "reactstrap";
import Timeline from "./Timeline";
import { ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT } from "../../../../routes/routesConstants";
class HomePageClient extends React.Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };
  componentDidMount() {
    this.props.listPhotoshootClient(0);
  }

  gotToEditPhotoshoot(id) {
    history.push(ROUTE_TO_PHOTOSHOOT_PROCESS_CLIENT + "/" + id);
  }
  render() {
    const { user } = this.props.auth;
    console.log(user);
    return (
      <>
        <div
          style={{
            display: "flex",
            flex: "8",
            flexDirection: "column",
            aligItems: "center",
            justifyContent: "center",
          }}
        >
          {this.props.photoshoot.listphotoshoot ? (
            <div>
              <h5>
                <span style={{ fontWeight: "bold" }}>Hello, </span>
                <span className="text-primary">{user.name}</span>
              </h5>
              {this.props.photoshoot.listphotoshoot.length > 0 ? (
                <Timeline
                  listphotoshoot={this.props.photoshoot.listphotoshoot}
                  onClickPhotoshoot={this.gotToEditPhotoshoot}
                />
              ) : (
                <div style={{ display: "flex" }}>No photoshoot yet.</div>
              )}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Spinner size="lg" color="primary" />
            </div>
          )}
        </div>
      </>
    );
  }
}

HomePageClient.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  listPhotoshootClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, { logoutUser, listPhotoshootClient })(
  HomePageClient
);
