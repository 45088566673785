import axios from "axios";
import store from "./store";
import history from "./history";
import { ROUTE_TO_LOGOUT } from "./routes/routesConstants";
import { GET_ALERTS } from "./actions/types";
// Add a request interceptor
axios.interceptors.response.use(
  function (response) {
    console.log(response);
    if (response.data.message) {
      console.log(response);
      store.dispatch({
        type: GET_ALERTS,
        payload: {
          type: response.data.type || "success",
          message: response.data.message,
        },
      });
    }
    return response;
  },
  function (error) {
    // Do something with request error
    if (error.response.status === 404) {
      history.push("/dashboard/404-page");
    } else if (
      error.response.status === 403 &&
      error.response.data &&
      error.response.data.action &&
      error.response.data.action === "logout"
    ) {
      history.push(ROUTE_TO_LOGOUT);
    }
    if (error.response.data.message) {
      console.log("Adding the Error in Store");
      console.log(error.response);
      store.dispatch({
        type: GET_ALERTS,
        payload: {
          type: error.response.data.type || "error",
          message: error.response.data.message,
        },
      });
    }

    return Promise.reject(error);
  }
);

export default axios;
