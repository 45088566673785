import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import {
  addPhotoshoot,
  editPhotoshootPhotographer,
  emptyCurrentPhotoshoot,
  uploadPictures,
  activateClient,
  getPhotoshoot,
} from "../../../../actions/photoshootActions";
import history from "../../../../history";
// reactstrap components
import {
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

// core components
import GetName from "../../../../components/photoshootPhotographer/GetName";
import Shooting from "../../../../components/photoshootPhotographer/Shooting";
import GetEmail from "../../../../components/photoshootPhotographer/GetEmail";
import UploadPictures from "../../../../components/photoshootPhotographer/UploadPictures";
import WaitingForPayment from "../../../../components/photoshootPhotographer/WaitingForPayment";
import PhotoshootFinished from "../../../../components/photoshootPhotographer/PhotoshootFinished";
import CardPhotoshoot from "../../../../components/layout/CardPhotoshoot";
import GetPrice from "../../../../components/photoshootPhotographer/GetPrice";
import { ROUTE_TO_HOME } from "../../../../routes/routesConstants";
import store from "../../../../store";
import { COLORS } from "../../../../style/Constants";
const is_mobile = window.innerWidth < 768;
class StepOrchestratorPhotographer extends React.Component {
  constructor(props) {
    super(props);

    this.setNameClient = this.setNameClient.bind(this);
    this.setEmailClient = this.setEmailClient.bind(this);
    this.binaryStep = this.binaryStep.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.setPictures = this.setPictures.bind(this);
    this.stepsList = [
      "getName",
      "shoot",
      "joinClient",
      "setPrice",
      "uploadPictures",
      "buyPictures",
      "paymentDone",
    ];
    this.stepsLogic = {
      getName: {
        component: GetName,
        callback: (name) => this.setNameClient(name, "shoot"),
      },
      shoot: {
        component: Shooting,
        callback: () => this.goToStep("joinClient"),
      },
      joinClient: {
        component: GetEmail,
        callback: (email) => this.setEmailClient(email, "setPrice"),
      },
      setPrice: {
        component: GetPrice,
        callback: (price) =>
          this.setPrice_not_flexible(price, "uploadPictures"),
      },
      uploadPictures: {
        component: UploadPictures,
        action: (files) => this.setPictures(files),
        callback: () => this.goToStep("buyPictures"),
      },
      buyPictures: {
        component: WaitingForPayment,
        callback: (paymentMethod) => {
          alert(paymentMethod);
          if (paymentMethod) {
            this.setPaymentMethod(paymentMethod, "paymentDone");
          } else {
            history.push(ROUTE_TO_HOME);
          }
        },
      },
      paymentDone: {
        component: PhotoshootFinished,
        callback: () => history.push(ROUTE_TO_HOME),
      },
    };
    this.state = {
      step: props?.match.params.step || false,
      nameclient: "",
      loading: true,
    };
  }
  componentDidMount() {
    if (this.props.match.params.photoshootid) {
      // not the case if new photoshot
      store
        .dispatch(getPhotoshoot(this.props.match.params.photoshootid))
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  setNameClient = (clientname, nextstepname) => {
    if (!this.props.photoshoot?.photoshoot?._id) {
      this.props.addPhotoshoot({
        clientName: clientname,
        step: this.isCurrentStepLast()
          ? nextstepname
          : this.props.photoshoot.photoshoot.step,
      });
    } else {
      this.props.editPhotoshootPhotographer(
        this.props.photoshoot.photoshoot._id,
        {
          clientName: clientname,
        }
      );
    }
  };

  setPrice_not_flexible = (editingOffered, nextstepname) => {
    this.props.editPhotoshootPhotographer(
      this.props.photoshoot.photoshoot._id,
      {
        editingOffered: editingOffered,
        step: this.isCurrentStepLast()
          ? nextstepname
          : this.props.photoshoot.photoshoot.step,
      }
    );
  };
  setPrice = (price, nextstepname) => {
    this.props.editPhotoshootPhotographer(
      this.props.photoshoot.photoshoot._id,
      {
        price: price,
        step: this.isCurrentStepLast()
          ? nextstepname
          : this.props.photoshoot.photoshoot.step,
      }
    );
  };

  setPaymentMethod = (paymentMethod, nextstepname) => {
    this.props.editPhotoshootPhotographer(
      this.props.photoshoot.photoshoot._id,
      {
        paymentMethod: paymentMethod,
        step: this.isCurrentStepLast()
          ? nextstepname
          : this.props.photoshoot.photoshoot.step,
      }
    );
  };

  setEmailClient = (emailclient, nextstepname) => {
    this.props.activateClient(
      this.props.photoshoot.photoshoot._id,
      this.props.photoshoot.photoshoot.clientName,
      emailclient,
      () =>
        this.props.editPhotoshootPhotographer(
          this.props.photoshoot.photoshoot._id,
          {
            clientEmail: emailclient,
            step: this.isCurrentStepLast()
              ? nextstepname
              : this.props.photoshoot.photoshoot.step,
          }
        )
    );
  };

  goToStep = (stepname) => {
    if (!this.isCurrentStepLast()) {
      // we dont need to update the photoshoot with a new step
      this.setState({ step: stepname });
    } else {
      this.props.editPhotoshootPhotographer(
        this.props.photoshoot.photoshoot._id,
        {
          step: stepname,
        }
      );
      this.setState({ step: stepname });
    }
  };

  isCurrentStepLast = () => {
    console.log(
      "Comparing " +
        this.state.step +
        " " +
        this.props.photoshoot.photoshoot.step
    );
    return (
      this.stepsList.indexOf(this.state.step) ==
        this.stepsList.indexOf(this.props.photoshoot.photoshoot.step) ||
      !this.state.step
    );
  };
  setPictures = (files) => {
    this.props.uploadPictures(this.props.photoshoot.photoshoot._id, files);
  };

  finishLater = () => {
    this.props.emptyCurrentPhotoshoot();
    history.push(ROUTE_TO_HOME);
  };
  binaryStep = (bool, stepIfTrue, stepIfFalse) => {
    if (bool === true) {
      this.goToStep(stepIfTrue);
    } else {
      this.goToStep(stepIfFalse);
    }
  };

  render() {
    const current_photoshoot = this.props.photoshoot.photoshoot;

    let current_step = this.stepsList[0];
    if (this.state.step && this.stepsList.includes(this.state.step)) {
      current_step = this.state.step;
    } else if (this.stepsList.includes(current_photoshoot.step)) {
      current_step = current_photoshoot.step;
    }

    const StepComponent = this.stepsLogic[current_step].component;
    const stepCallback = this.stepsLogic[current_step].callback;
    const stepAction = this.stepsLogic[current_step].action;
    const badges_per_step = {
      getName: <span class="badge badge-info">Uncomplete</span>,
      setPrice: <span class="badge badge-info">Uncomplete</span>,
      shoot: <span class="badge badge-info">Uncomplete</span>,
      joinClient: <span class="badge badge-info">Uncomplete</span>,
      uploadPictures: <span class="badge badge-info">Uncomplete</span>,
      buyPictures: <span class="badge badge-secondary">Complete</span>,
      paymentDone: <span class="badge badge-success">Succeeded</span>,
      default: "ni ni-settings-gear-63-2",
    };

    var stepsNames = {
      getName: "Client's information",
      shoot: "Photoshoot",
      joinClient: "Invite client",
      setPrice: "Pricing",
      uploadPictures: "Pictures Upload",
      buyPictures: "Send Pictures",
      paymentDone: "Payment",
    };
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center", marginTop: 150 }}>
          <Spinner size="sm" />
        </div>
      );
    }
    return (
      <>
        <div style={{}}>
          <Row
            style={{
              marginBottom: 8,
              marginTop: 21,
              paddingLeft: 34,
              paddingRight: 34,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid " + COLORS.grey_2,
              paddingBottom: 21,
            }}
          >
            <Col sm={10}>
              <h5 className="display-5 text-primary">Photoshoot</h5>
              <h4 className="display-5" style={{ fontWeight: 600 }}>
                {current_photoshoot.clientName
                  ? current_photoshoot.clientName + ", "
                  : "New photoshoot"}
                {current_photoshoot.location &&
                  current_photoshoot?.location.split(",")[0]}{" "}
                {badges_per_step[current_photoshoot.step]}
              </h4>
            </Col>
          </Row>

          <div
            style={{
              paddingLeft: 34,
              paddingRight: 34,
              flex: 0,
            }}
          >
            {is_mobile ? (
              <UncontrolledDropdown
                style={{ width: "100%", marginBottom: 34 }}
                isOpen={this.state.dropdownOpen}
                toggle={() =>
                  this.setState({ dropdownOpen: !this.state.dropdownOpen })
                }
              >
                <DropdownToggle
                  id="dropdownMenuButton"
                  caret
                  color="white"
                  style={{
                    width: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    border: "2px solid " + COLORS.grey_3,
                  }}
                >
                  {stepsNames[current_step]}
                </DropdownToggle>
                <DropdownMenu
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "100%" }}
                >
                  {Object.keys(stepsNames).map((item) => {
                    var is_active = current_step == item;
                    var is_step_available =
                      this.stepsList.indexOf(item) <=
                      this.stepsList.indexOf(current_photoshoot.step);
                    return (
                      <DropdownItem
                        className={!is_step_available ? "text-muted" : ""}
                        onClick={() =>
                          is_step_available && this.setState({ step: item })
                        }
                        style={{ fontWeight: is_active ? "700" : "500" }}
                      >
                        {stepsNames[item]}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <ul class="nav">
                {Object.keys(stepsNames).map((item) => {
                  var is_active = current_step == item;
                  var is_step_available =
                    this.stepsList.indexOf(item) <=
                    this.stepsList.indexOf(current_photoshoot.step);
                  return (
                    <li class="nav-item">
                      <a
                        class={
                          "nav-link  " +
                          (is_active ? "active" : " ") +
                          (is_step_available ? " hoverable " : "")
                        }
                        style={{
                          fontSize: 15,
                          lineHeight: "40px",
                          fontWeight: is_step_available ? "600" : "500",
                          color: is_step_available
                            ? COLORS.text_black_secondary
                            : "rgba(0,0,0,0.3)",
                          borderBottom: is_active
                            ? "3px solid " + COLORS.accent_color
                            : null,
                        }}
                        onClick={() =>
                          is_step_available && this.setState({ step: item })
                        }
                      >
                        {stepsNames[item]}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingRight: 34,
            paddingLeft: 34,
            backgroundColor: "#fff",
          }}
        >
          <Card
            className="card-profile "
            style={{
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <StepComponent
              callback={stepCallback}
              current_photoshoot={current_photoshoot}
              action={stepAction}
            />
          </Card>
        </div>
      </>
    );
  }
}

StepOrchestratorPhotographer.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  addPhotoshoot: PropTypes.func.isRequired,
  editPhotoshootPhotographer: PropTypes.func.isRequired,
  uploadPictures: PropTypes.func.isRequired,
  emptyCurrentPhotoshoot: PropTypes.func.isRequired,
  activateClient: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  logoutUser,
  addPhotoshoot,
  editPhotoshootPhotographer,
  emptyCurrentPhotoshoot,
  uploadPictures,
  activateClient,
})(StepOrchestratorPhotographer);
