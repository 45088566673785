import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
// reactstrap components
import { Spinner } from "reactstrap";
import { ROUTE_TO_LOGIN } from "../../../routes/routesConstants";

class Logout extends React.Component {
  componentDidMount() {
    this.props.logoutUser();
    this.props.history.push(ROUTE_TO_LOGIN);
  }

  render() {
    return (
      <>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h3 className="display-3"> Logging out...</h3>

          <Spinner color="primary" size="md"></Spinner>
        </div>
      </>
    );
  }
}

Logout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Logout);
