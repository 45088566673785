import React from "react";

// reactstrap components

class NavbarButton extends React.Component {
  render() {
    return (
      <>
        <div
          className="hoverable"
          style={{
            flex: 1,
            textAlign: "center",
            paddingTop: "20px",
            color: "black"
          }}
          onClick={this.props.onclick}
        >
          <i
            className={
              this.props.iconclass +
              " " +
              (this.props.active ? "text-primary" : "text-default")
            }
          ></i>

          <p
            className={this.props.active ? "text-primary" : "text-default"}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              zIndex: 2,
              position: "relative"
            }}
          >
            {this.props.title}
          </p>
        </div>
      </>
    );
  }
}

export default NavbarButton;
