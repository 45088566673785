import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import GalleryPreview from "../layout/GalleryPreview";
// reactstrap components
import { Button, Row, Col } from "reactstrap";

class PhotoshootFinished extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <Row className="justify-content-center">
        <Col lg="12">
          <h5>
            <b>Pictures Unlocked</b>
          </h5>
          <p> We wish we were as photogenic as you !</p>
          <p>
            You can find your receipt{" "}
            <a href={this.props.current_photoshoot.receipt_url}>
              by clicking here
            </a>
            .
          </p>
          {this.props.current_photoshoot.picturesList.length > 0 && (
            <GalleryPreview
              picturesList={this.props.current_photoshoot.picturesList
                .slice()
                .reverse()}
              locked={false}
            />
          )}

          <Button
            color="secondary"
            size="lg"
            style={{ width: "100%" }}
            onClick={() => this.props.callback()}
          >
            {" "}
            Go Home
          </Button>
        </Col>
      </Row>
    );
  }
}

PhotoshootFinished.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(PhotoshootFinished);
