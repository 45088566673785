import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  ROUTE_TO_LOGIN,
  ROUTE_TO_NOT_ALLOWED,
} from "../../routes/routesConstants";

const PrivateRoute = ({ component: Component, auth, typeofuser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated === true ? (
        !typeofuser ||
        (auth.user.type && auth.user.type.includes(typeofuser)) ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTE_TO_NOT_ALLOWED} />
        )
      ) : (
        <Redirect to={ROUTE_TO_LOGIN} />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
