import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import GalleryPreview from "../layout/GalleryPreview";
// reactstrap components
import { Button, Row, Col, Spinner } from "reactstrap";
import { COLORS } from "../../style/Constants";

class UploadPicture extends React.Component {
  constructor(props) {
    super();
    this.state = {
      picturesSelected: false,
      numberPictures: 0,
      uploadingPictures: false,
      picturesToUpload: [],
      picturesList: props.current_photoshoot.picturesList || [],
      drag: false,
      selected_indexes: [],
    };
  }
  dropRef = React.createRef();
  browseInputRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.onAddFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }
  handleBrowse(e) {
    e.preventDefault();
    this.browseInputRef.current.click();
  }

  toggleSelectIndex(selected_index) {
    var indexes = this.state.selected_indexes;
    if (indexes.includes(selected_index)) {
      indexes = indexes.filter(function (value, index, arr) {
        return value != selected_index;
      });
    } else {
      indexes.push(selected_index);
    }
    this.setState({ selected_indexes: indexes });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.current_photoshoot.picturesList !== state.picturesList) {
      // there has been a successful update
      return {
        picturesSelected: false,
        numberPictures: 0,
        uploadingPictures: false,
        picturesToUpload: [],
        picturesList: props.current_photoshoot.picturesList || [],
      };
    }
    return null;
  }

  onAddFiles = (files) => {
    const new_files = Array.from(files);
    this.setState({
      picturesToUpload: this.state.picturesToUpload.concat(new_files),
    });

    // this.onClickUpload();
  };
  onChange = (e) => {
    console.log("IOnc hange");
    const files = e.dataTransfer ? e.dataTransfer.files : e.currentTarget.files;
    this.onAddFiles(files);
  };

  onClickUpload = () => {
    this.setState({ uploadingPictures: true });
    this.props.action(this.state.picturesToUpload);
  };

  render() {
    console.log(this.state.picturesToUpload);
    return (
      <Row className="justify-content-center" style={{}}>
        <Col lg="4" md="12">
          <h5 style={{ marginBottom: "20px", fontWeight: 600 }}>
            Upload the pictures
          </h5>
          <p className="mb-3 mt--3 text-muted">
            You now have to upload the pictures to complete the photoshoot.
          </p>
          <div
            style={{
              flexDirection: "column",
              width: "100%",
              position: "relative",
              minHeight: "100px",
              backgroundColor: COLORS.background_color_secondary,
              border: "dashed grey 2px",
              borderColor: COLORS.grey_3,
              borderRadius: 5,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            ref={this.dropRef}
          >
            {this.state.drag && (
              <div
                style={{
                  backgroundColor: COLORS.accent_color,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 9999,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    left: 0,
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 15,
                  }}
                >
                  <div>Drop your pictures here</div>
                </div>
              </div>
            )}
            <div style={{}}>
              <input
                id="img-input"
                ref={this.browseInputRef}
                type="file"
                onChange={this.onChange}
                style={{ width: 0, height: 0, overflow: "hidden" }}
              />
              <a href="" onClick={(e) => this.handleBrowse(e)}>
                Browse pictures
              </a>{" "}
              or simply drag and drop
            </div>
          </div>
          {this.state.picturesToUpload.length > 0 && (
            <Button
              color="default"
              size="lg"
              style={{ width: "100%", marginTop: "20px" }}
              onClick={() => this.onClickUpload()}
              disabled={this.state.uploadingPictures}
            >
              {this.state.uploadingPictures ? (
                <Spinner size="lg" color="primary" />
              ) : (
                "Upload Pictures (" + this.state.picturesToUpload.length + ")"
              )}
            </Button>
          )}
          {this.props.current_photoshoot.picturesList.length > 0 && (
            <Button
              disabled={this.props.current_photoshoot.picturesList.length === 0}
              color="default"
              size="lg"
              style={{ marginTop: 34, width: "100%" }}
              onClick={() => this.props.callback()}
            >
              I'm done uploading
            </Button>
          )}
        </Col>
        <Col lg="8" md="12">
          <Button
            color="default"
            style={{ marginLeft: 15, marginBottom: 13, marginTop: 13 }}
            disabled={this.state.selected_indexes.length == 0}
          >
            Delete Selection ({this.state.selected_indexes.length})
          </Button>
          {this.props.current_photoshoot.picturesList.length > 0 && (
            <GalleryPreview
              onSelect={(i) => this.toggleSelectIndex(i)}
              select_mode={true}
              selected_indexes={this.state.selected_indexes}
              size_col={2}
              picturesList={this.props.current_photoshoot.picturesList
                .slice()
                .reverse()}
            />
          )}
        </Col>
      </Row>
    );
  }
}

UploadPicture.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(UploadPicture);
