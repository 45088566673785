import React from "react";

class ImageThumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div
        className="square"
        style={{
          width: "100%",
          backgroundImage: `url(${this.props.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "5px"
        }}
      ></div>
    );
  }
}

export default ImageThumbnail;
