import React from "react";

import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col, NavbarBrand, Spinner, Button } from "reactstrap";
import store from "../../store";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPhotoshoot } from "../../actions/photoshootActions";
import FlashAlert from "../../components/layout/FlashAlert";
import { getPublicProfile } from "../../actions/authActions";
import CardsFooter from "../../components/footers/CardsFooter";
// core components
class PhotographerProfileLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.main.scrollTop = 0;

    store
      .dispatch(getPublicProfile(this.props.match.params.photographerid))
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const photographer = this.props.photographer?.current_public_profile;
    var locked = true;
    var fees = 0;
    var prices = [0, 0];
    console.log(photographer);
    return (
      <>
        <FlashAlert />
        {!this.state.loading ? (
          photographer ? (
            <Row
              style={{
                overflowX: "hidden",
                minHeight: "100vh",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Col
                lg={locked ? "8" : "12"}
                md={locked ? "6" : "12"}
                sm="12"
                style={{
                  paddingTop: 30,
                  paddingBottom: 30,
                  margin: 0,
                }}
              >
                <NavbarBrand
                  className="mr-lg-5"
                  style={{ marginBottom: "20px", alignSelf: "flex-start" }}
                  to="/"
                  tag={Link}
                >
                  <img
                    style={{ opacity: 0.1, marginLeft: 5 }}
                    className="crisp-edges"
                    alt="..."
                    src="/images/brand/logo_black.png"
                  />
                </NavbarBrand>
                <div
                  style={{
                    background: "#fff",
                    justifyContent: "center",
                    flex: 1,
                    flexDirection: "row",
                    alignContent: "center",
                    textAlign: "center",
                    paddingTop: 0,
                  }}
                >
                  <img
                    className="rounded-circle"
                    style={{
                      height: "150px",
                      width: "150px",
                      position: "relative",
                      top: -75,
                      left: 0,
                      right: 0,
                      margin: "0 auto",
                    }}
                    src={
                      photographer.profilePicture
                        ? photographer.profilePicture
                        : "/images/icons/common/blank_user.svg"
                    }
                  />
                  <h2 style={{ fontWeight: "800", marginTop: -50 }}>
                    {photographer.name}
                  </h2>
                  <h4 style={{ marginTop: 0 }}>Photographer at Yoov</h4>
                  <Button
                    style={{
                      marginTop: 20,
                      marginBottom: 100,
                      alignSelf: "flex-start",
                    }}
                    size="lg"
                    color="primary"
                    href="/dashboard/support"
                  >
                    Contact our support
                  </Button>
                </div>
                <CardsFooter />
              </Col>
            </Row>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  position: "absolute",
                  top: "30%",
                  textAlign: "center",
                }}
              >
                <h1>Error Loading the page.</h1>
                <h5>
                  Are you sure of the link? This yoover doesn't seem to exist..
                </h5>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  textAlign: "center",
                  bottom: "20px",
                  justifyContent: "center",
                }}
              >
                <Link
                  style={{ textDecoration: "underline", color: "#7E7E7E" }}
                  to="/dashboard/support"
                >
                  Contact Support
                </Link>
              </div>
            </>
          )
        ) : (
          <>
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                textAlign: "center",
              }}
            >
              <Spinner animation="border" />
            </div>
            <div
              style={{
                width: "80%",
                position: "absolute",
                textAlign: "center",
                bottom: "20px",
                justifyContent: "center",
              }}
            >
              <Link
                style={{ textDecoration: "underline", color: "#7E7E7E" }}
                to="/dashboard/support"
              >
                Not loading? Contact Support
              </Link>
            </div>
          </>
        )}
      </>
    );
  }
}
PhotographerProfileLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
  photographer: state.photographer,
});

export default connect(mapStateToProps, {
  getPhotoshoot,
})(PhotographerProfileLanding);
