import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { is_mobile } from "../../helpers/global";
import { ROUTE_TO_PROFILE } from "../../routes/routesConstants";
import { COLORS, STYLES } from "../../style/Constants";
import SideNavbar from "../navbars_/SideNavbar";

class LayoutDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_sidebar_open: is_mobile ? false : true,
    };
  }
  render() {
    var user = this.props.auth.user;

    return (
      <>
        <div
          style={{
            backgroundColor: COLORS.grey_1,
            borderColor: COLORS.grey_2,
            borderWidth: 1,
            borderRightStyle: "solid",
            height: "100%",
            position: "fixed",
            zIndex: 1000,
            top: 0,
            left: 0,
            width: this.state.is_sidebar_open ? 250 : is_mobile ? 0 : 64,
            overflowX: this.state.is_sidebar_open ? "visible" : "hidden",
            transitionProperty: "width",
            transitionDuration: "0.1s",
          }}
        >
          {is_mobile && (
            <Button
              color="white"
              style={{
                position: "absolute",
                top: 8,
                right: -25,
                borderRadius: 1000,
                height: 50,
                width: 50,
                textAlign: "center",
                overflow: "visible",
                margin: 0,
                padding: 0,
                paddingTop: 5,
                fontSize: 25,
              }}
              onClick={() =>
                this.setState({
                  is_sidebar_open: !this.state.is_sidebar_open,
                })
              }
            >
              <i class="fi-rr-cross-small"></i>
            </Button>
          )}
          <SideNavbar
            onClickButton={() =>
              is_mobile ? this.setState({ is_sidebar_open: false }) : null
            }
          />
        </div>
        <div
          style={{
            transitionProperty: "margin-left",
            transitionDuration: "0.1s",
            marginLeft: is_mobile ? 0 : this.state.is_sidebar_open ? 250 : 64,

            minHeight: "100%",
            flexDirection: "column",
            display: "flex",
          }}
        >
          {this.props.auth.isAuthenticated ? (
            <Row style={{}}>
              <Col xs={12}>
                <nav
                  className="navbar navbar-top navbar-expand navbar-light border-bottom justify-content-between"
                  style={{ paddingTop: 0, paddingBottom: 0, width: "100%" }}
                >
                  {is_mobile && (
                    <Button
                      color="secondary"
                      style={{
                        marginLeft: 10,
                        width: 34,
                        height: 34,
                        padding: 0,
                        ...STYLES.elevation0,
                        backgroundColor: "#fff",
                        border: "none",
                        fontSize: 20,
                      }}
                      onClick={() =>
                        this.setState({
                          is_sidebar_open: !this.state.is_sidebar_open,
                        })
                      }
                    >
                      <i class="fi-rr-menu-burger"></i>
                    </Button>
                  )}
                  <span
                    class="navbar-brand h1"
                    style={{ paddingLeft: is_mobile ? 0 : 25 }}
                  >
                    Dashboard
                  </span>

                  <ul
                    className="navbar-nav align-items-center  ml-auto ml-md-0 "
                    style={{ paddingRight: 34 }}
                  >
                    <li className="nav-item dropdown">
                      <a
                        class="nav-link pr-0 text-dark"
                        href={ROUTE_TO_PROFILE}
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ fontWeight: 600 }}
                      >
                        <div class="media align-items-center">
                          <span class="avatar avatar-sm rounded-circle">
                            <img
                              alt="..."
                              className="rounded-circle shadow-sm"
                              src={
                                user.profilePicture
                                  ? user.profilePicture
                                  : "/images/icons/common/blank_user.svg"
                              }
                            />
                          </span>
                          <div class="media-body  ml-2  d-none d-lg-block">
                            {user.name}
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          ) : null}
          <Row
            style={{
              marginBottom: 0,
              paddingLeft: 0,
              paddingRight: 0,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Col
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {this.props.children}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LayoutDashboard);
