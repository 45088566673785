import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { patchUser } from "../../../actions/authActions";
// reactstrap components
import { Button, FormGroup, InputGroup, InputGroupText } from "reactstrap";
import history from "../../../history";
// reactstrap components
import { Input } from "reactstrap";
import { ROUTE_TO_HOME } from "../../../routes/routesConstants";

class EditLocation extends React.Component {
  constructor(props) {
    super(props);
    const locationPhotoshootArray = (
      this.props.auth.user.locationPhotoshoot || ""
    ).split(", ");
    this.state = {
      city:
        (locationPhotoshootArray.length > 1 && locationPhotoshootArray[1]) ||
        "",
      country:
        (locationPhotoshootArray.length > 2 && locationPhotoshootArray[2]) ||
        "",
      place:
        (locationPhotoshootArray.length > 0 && locationPhotoshootArray[0]) ||
        "",
      error: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onClick = () => {
    const userData = {
      id: this.props.auth.user.id,
      locationPhotoshoot:
        this.state.place + ", " + this.state.city + ", " + this.state.country,
    };
    // If also modified password
    if (userData.locationPhotoshoot !== "") {
      this.props.patchUser(userData);
    } else {
      this.setState({ error: " The location you are trying to set is empty." });
    }
  };

  render() {
    return (
      <div>
        <h4 style={{ fontWeight: "700" }}>Edit Location</h4>
        <h6 style={{ marginBottom: "30px" }}>
          Updating the location will help your customer remember where the
          photoshoot took place.
        </h6>
        <FormGroup>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Landmark
          </p>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Landmark or Neighborhood"
              type="input"
              onChange={this.onChange}
              value={this.state.place}
              id="place"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            City
          </p>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="City"
              type="input"
              onChange={this.onChange}
              value={this.state.city}
              id="city"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Country / State
          </p>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder="Country / State"
              type="input"
              onChange={this.onChange}
              value={this.state.country}
              id="country"
            />
          </InputGroup>
        </FormGroup>

        <Button
          style={{ marginTop: "10px" }}
          color="primary"
          onClick={() => this.onClick()}
          type="submit"
          disabled={
            !this.state.place || !this.state.country || !this.state.city
          }
        >
          Save the location
        </Button>
      </div>
    );
  }
}

EditLocation.propTypes = {
  auth: PropTypes.object.isRequired,
  patchUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { patchUser })(EditLocation);
