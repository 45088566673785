import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import { Card } from "reactstrap";
// reactstrap components
import { Button, Row, Col, Spinner, NavbarBrand } from "reactstrap";
import { computePrice } from "../../../../helpers/global";
import GalleryPreview from "../../../../components/layout/GalleryPreview";
import store from "../../../../store";
import { getPhotoshoot } from "../../../../actions/photoshootActions";
import { Link } from "react-router-dom";
import { uploadPictures } from "../../../../actions/photoshootActions";
import axios from "../../../../axios";
import FlashAlert from "../../../../components/layout/FlashAlert";
import moment from "moment";
import { COLORS } from "../../../../style/Constants";
// core components
class UploadPicturesLanding extends React.Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      picturesSelected: false,
      numberPictures: 0,
      uploadingPictures: false,
      picturesToUpload: [],
      picturesList: [],
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.match.params.jwtToken;

    //this.refs.main.scrollTop = 0;
    store
      .dispatch(getPhotoshoot(this.props.match.params.photoshootid))
      .then(() => this.setState({ loading: false }));
  }

  onChange = (e) => {
    const files = Array.from(e.target.files);
    this.setState({ picturesToUpload: files });
  };

  onClickUpload = () => {
    this.setState({ uploadingPictures: true });
    store
      .dispatch(
        uploadPictures(
          this.props.match.params.photoshootid,
          this.state.picturesToUpload
        )
      )
      .then(() => this.setState({ uploadingPictures: false }));
  };

  render() {
    if (this.state.loading || !this.props.photoshoot.photoshoot) {
      return (
        <>
          <div
            style={{
              width: "100%",
              position: "absolute",
              top: "50%",
              textAlign: "center",
            }}
          >
            <Spinner size="lg" color="primary" />
          </div>
          <div
            style={{
              width: "100%",
              position: "absolute",
              textAlign: "center",
              bottom: "20px",
              justifyContent: "center",
            }}
          >
            {!this.props.photoshoot.photoshoot && (
              <p style={{ color: "#7E7E7E" }}>
                It looks like this link is wrong
              </p>
            )}
            <Link
              style={{ textDecoration: "underline", color: "#7E7E7E" }}
              to="/dashboard/support"
            >
              Not loading? Contact Support
            </Link>
          </div>
        </>
      );
    } else {
      const photoshoot = this.props.photoshoot.photoshoot;
      const prices = computePrice(
        photoshoot.equipment,
        photoshoot.editingOffered
      );
      const fees = prices[0] - prices[1];
      console.log(photoshoot);
      const locked = photoshoot.step !== "paymentDone";
      return (
        <>
          <FlashAlert />
          <NavbarBrand
            className="mr-lg-5"
            style={{
              marginLeft: "30px",
              marginBottom: "20px",
              marginTop: "20px",
              textAlign: "center",
            }}
            to="/"
            tag={Link}
          >
            <img alt="..." src="/images/brand/logo_black.png" />
          </NavbarBrand>
          <Row className="justify-content-center" style={{}}>
            <Col lg="8" md="10" s="12" style={{ padding: 50, paddingTop: 20 }}>
              <h6 style={{ fontWeight: "400", fontSize: 13 }}>
                {" "}
                {moment(photoshoot.createdAt).format(
                  "dddd, MMMM Do YYYY, h:mm a"
                )}
              </h6>
              <h6 style={{ fontWeight: "400", fontSize: 13 }}>
                {photoshoot.location}
              </h6>
              <hr style={{ marginTop: "30px", marginBottom: "30px" }} />
              <h6 style={{ fontWeight: "600", fontSize: 13 }}>
                Upload Pictures
              </h6>
              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <input
                  type="file"
                  id="picturesToUpload"
                  multiple
                  color="default"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 10,
                    paddingLeft: "10px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    width: "100%",
                    textAlign: "center",
                    margin: "0 auto",
                    borderRadius: 5,
                  }}
                  onChange={(e) => this.onChange(e)}
                />
                {this.state.picturesToUpload.length > 0 && (
                  <Button
                    color="primary"
                    size="lg"
                    style={{ width: "100%", marginTop: 10 }}
                    onClick={() => this.onClickUpload()}
                    disabled={this.state.uploadingPictures}
                  >
                    {this.state.uploadingPictures ? (
                      <Spinner size="lg" color="primary" />
                    ) : (
                      "Upload Pictures(" +
                      this.state.picturesToUpload.length +
                      ")"
                    )}
                  </Button>
                )}
              </Row>

              <hr style={{ marginTop: "30px", marginBottom: "30px" }} />

              {photoshoot.picturesList.length > 0 && (
                <GalleryPreview
                  picturesList={photoshoot.picturesList.slice().reverse()}
                />
              )}

              {photoshoot.picturesList.length > 0 && (
                <div>
                  <p>If done, go back to the app to go to the next step.</p>
                </div>
              )}
            </Col>
          </Row>
        </>
      );
    }
  }
}

UploadPicturesLanding.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  uploadPictures: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, { logoutUser, uploadPictures })(
  UploadPicturesLanding
);
