import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import GalleryPreview from "../layout/GalleryPreview";
// reactstrap components
import { Button, Row, Col, CardBody } from "reactstrap";
import { Elements } from "react-stripe-elements";
import InjectedCheckoutForm from "./../payment/CheckoutForm";
import { computePrice, computePriceWithOptions } from "../../helpers/global";
class BuyPictures extends React.Component {
  constructor(props) {
    super();
    this.state = {
      displayCheckout: false,
    };
  }
  onToken = (token) => {
    console.log(token);
  };

  onClickPurchase = () => {
    this.setState({ displayCheckout: true });
  };
  render() {
    const prices = computePrice(this.props.current_photoshoot.price);
    const fees = prices[0] - prices[1];
    return (
      <Row className="justify-content-center">
        {this.state.displayCheckout ? (
          <Col lg="12">
            <div
              className=""
              style={{
                padding: "20px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <h6>
                <b>Unlock the pictures</b>
              </h6>
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  lineHeight: "40px",
                }}
              >
                <div style={{ float: "left" }}>
                  Photoshoot{" "}
                  {this.props.current_photoshoot.editingOffered &&
                    "and Editing"}
                </div>
                <div style={{ float: "right" }}>${prices[1]}</div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  lineHeight: "40px",
                }}
              >
                <div style={{ float: "left" }}>Taxes and Fees</div>
                <div style={{ float: "right" }}>${fees.toFixed(1)}</div>
              </div>

              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
              <div
                style={{
                  width: "100%",
                  height: "40px",

                  lineHeight: "40px",
                }}
              >
                <div style={{ float: "left", fontWeight: "700" }}>
                  Total Due
                </div>
                <div
                  style={{
                    float: "right",
                    fontWeight: "700",
                  }}
                >
                  ${prices[0]}
                </div>
              </div>
            </div>

            <CardBody className="bg-secondary" style={{ borderRadius: "5px" }}>
              <Elements>
                <InjectedCheckoutForm
                  current_photoshoot={this.props.current_photoshoot}
                />
              </Elements>
            </CardBody>
          </Col>
        ) : (
          <Col lg="12">
            <h5 style={{}}>Preview of the pictures</h5>
            <p>The photographer updated the pictures. They look great !</p>
            {this.props.current_photoshoot.picturesList.length > 0 && (
              <GalleryPreview
                picturesList={this.props.current_photoshoot.picturesList
                  .slice()
                  .reverse()}
                locked={true}
              />
            )}
            <Button
              color="default"
              size="lg"
              style={{ width: "100%", marginTop: "50px" }}
              onClick={() => this.onClickPurchase()}
            >
              Unlock the pictures
            </Button>
          </Col>
        )}
      </Row>
    );
  }
}

BuyPictures.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(BuyPictures);
