import React from "react";

import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col, NavbarBrand, Spinner } from "reactstrap";
import store from "../../../../store";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPhotoshoot } from "../../../../actions/photoshootActions";
import GalleryPreview from "../../../../components/layout/GalleryPreview";

import {
  computePrice,
  computePriceWithOptions,
} from "../../../../helpers/global";
import FlashAlert from "../../../../components/layout/FlashAlert";
import CheckoutFormClient from "./CheckoutFormClient";
// core components
class BuyPicturesLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, payButtonDisabled: true, cardToken: false };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //this.refs.main.scrollTop = 0;

    store
      .dispatch(getPhotoshoot(this.props.match.params.photoshootid))
      .then(() => this.setState({ loading: false }));
  }

  onCardTokenized = (token) => {
    this.setState({ cardToken: token });
  };

  onCardValidationChanged = (valid) => {
    this.setState({ payButtonDisabled: !valid });
  };

  render() {
    const photoshoot = this.props.photoshoot?.photoshoot;
    var locked = true;
    var fees = 0;
    var prices = [0, 0];

    if (photoshoot) {
      const raw_price = photoshoot.price;

      prices = computePrice(raw_price);
      fees = prices[0] - prices[1];

      locked = photoshoot.step !== "paymentDone";
    }

    return (
      <>
        <FlashAlert />
        {!this.state.loading ? (
          photoshoot ? (
            <Row style={{ overflowX: "hidden", maxWidth: "100vw" }}>
              <Col
                lg={locked ? "8" : "12"}
                md={locked ? "6" : "12"}
                sm="12"
                style={{
                  background: "#fefefe",
                  paddingLeft: "10vw",
                  paddingRight: "10vw",
                  paddingTop: "30px",
                  margin: 0,
                }}
              >
                <NavbarBrand
                  className="mr-lg-5"
                  style={{ marginBottom: "20px" }}
                  to="/"
                  tag={Link}
                >
                  <img
                    className="crisp-edges"
                    alt="..."
                    src="/images/brand/logo_black.png"
                  />
                </NavbarBrand>

                <h6 style={{ color: "#000" }}>
                  <b>{photoshoot.location}</b> Photoshoot by{" "}
                  {photoshoot.photographerName}.
                </h6>
                <GalleryPreview
                  picturesList={photoshoot.picturesList.slice().reverse()}
                  locked={locked}
                ></GalleryPreview>
                <p style={{ textAlign: "center", marginTop: "15px" }}>
                  {locked && photoshoot.picturesList.length > 6 && (
                    <div style={{ paddingBottom: "10px" }}>
                      and {photoshoot.picturesList.length - 6} other pictures.
                    </div>
                  )}
                </p>
              </Col>
              {locked && (
                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  style={{
                    minHeight: "100vh",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "50px",
                    color: "#000",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  <h5 style={{ color: "#000", fontWeight: "800" }}>
                    Unlock all the pictures
                  </h5>
                  <h6 style={{ fontSize: "12px", color: "#727272" }}>
                    You will be able to download them in high definition.
                  </h6>
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      lineHeight: "40px",
                    }}
                  >
                    <div style={{ float: "left" }}>
                      Photoshoot {photoshoot.editingOffered && "and Editing"}
                    </div>
                    <div style={{ float: "right" }}>
                      ${prices[1].toFixed(2)}
                    </div>
                  </div>
                  <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      lineHeight: "40px",
                    }}
                  >
                    <div style={{ float: "left" }}>Taxes and Fees</div>
                    <div style={{ float: "right" }}>${fees.toFixed(2)}</div>
                  </div>
                  <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                  <div
                    style={{
                      width: "100%",
                      height: "40px",

                      lineHeight: "40px",
                    }}
                  >
                    <div style={{ float: "left", fontWeight: "700" }}>
                      Total Due
                    </div>
                    <div
                      style={{
                        float: "right",
                        fontWeight: "700",
                      }}
                    >
                      ${prices[0].toFixed(2)}
                    </div>
                  </div>
                  <hr
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      marginLeft: "-50px",
                      marginRight: "-50px",
                    }}
                  />
                  <h6 style={{ color: "#000" }}>
                    <b>Payment details</b>
                  </h6>
                  <div>
                    <CheckoutFormClient total_due={prices[0].toFixed(2)} />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "underline", color: "#7E7E7E" }}
                      to="/dashboard/support"
                    >
                      Contact Support
                    </Link>
                  </div>
                </Col>
              )}
            </Row>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  position: "absolute",
                  top: "30%",
                  textAlign: "center",
                }}
              >
                <h1>Error Loading the page.</h1>
                <h5>
                  Are you sure of the link? This photoshoot doesn't seem to
                  exist..
                </h5>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  textAlign: "center",
                  bottom: "20px",
                  justifyContent: "center",
                }}
              >
                <Link
                  style={{ textDecoration: "underline", color: "#7E7E7E" }}
                  to="/dashboard/support"
                >
                  Contact Support
                </Link>
              </div>
            </>
          )
        ) : (
          <>
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: "50%",
                textAlign: "center",
              }}
            >
              <Spinner animation="border" />
            </div>
            <div
              style={{
                width: "80%",
                position: "absolute",
                textAlign: "center",
                bottom: "20px",
                justifyContent: "center",
              }}
            >
              <Link
                style={{ textDecoration: "underline", color: "#7E7E7E" }}
                to="/dashboard/support"
              >
                Not loading? Contact Support
              </Link>
            </div>
          </>
        )}
      </>
    );
  }
}
BuyPicturesLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  photoshoot: state.photoshoot,
});

export default connect(mapStateToProps, {
  getPhotoshoot,
})(BuyPicturesLanding);
