import React from "react";
// reactstrap components
import NavbarButton from "./NavbarButton";

import { Row, Col, NavbarBrand } from "reactstrap";
import history from "../../history";
import {
  ROUTE_TO_PHOTOSHOOT_LIST,
  ROUTE_TO_SUPPORT,
  ROUTE_TO_HOME,
  ROUTE_TO_PROFILE,
} from "../../routes/routesConstants";
import { Link } from "react-router-dom";
import { COLORS } from "../../style/Constants";
export default class SideNavbar extends React.Component {
  constructor() {
    super();

    this.state = {
      current_nav: "Home",
    };
    this.menusList = {
      Home: { iconclass: "home", path: ROUTE_TO_HOME },
      Shoots: {
        iconclass: "camera",
        path: ROUTE_TO_PHOTOSHOOT_LIST,
      },
      Support: {
        iconclass: "paper-plane",
        path: ROUTE_TO_SUPPORT,
      },
      Profile: { iconclass: "user", path: ROUTE_TO_PROFILE },
    };
  }
  onclick(menu) {
    this.setState({ current_nav: menu });
    history.push(this.menusList[menu].path);
    this.props.onClickButton();
  }

  componentDidMount() {}
  render() {
    return (
      <>
        <ul class="nav flex-column" style={{ paddingTop: 34 }}>
          <NavbarBrand
            style={{ textAlign: "center", paddingBottom: 34 }}
            to="/"
            tag={Link}
          >
            <img
              className="crisp-edges"
              alt="yoov logo"
              src={"/images/brand/logo_black.png"}
            />
          </NavbarBrand>
          <li class="nav-item" style={{ padding: 0 }}>
            {Object.keys(this.menusList).map((menu, index) => {
              const active = this.state.current_nav === menu;
              return (
                <div
                  className="hoverable"
                  style={{
                    flex: 1,
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "21px",
                    background: active ? "transparent" : "transparent",
                    borderRightColor: active
                      ? COLORS.accent_color
                      : "transparent",
                    borderRightWidth: 0,
                    borderRightStyle: "solid",
                  }}
                  onClick={() => this.onclick(menu)}
                >
                  <Row style={{}}>
                    <Col
                      xs={"3"}
                      style={{
                        textAlign: "right",
                        alignSelf: "center",
                      }}
                    >
                      <i
                        className={
                          (active ? "fi-sr-" : "fi-rr-") +
                          this.menusList[menu].iconclass +
                          " " +
                          (active ? "text-primary" : "")
                        }
                      ></i>
                    </Col>
                    <Col xs={"9"}>
                      <p
                        className={active ? "text-primary" : ""}
                        style={{
                          textAlign: "left",
                          zIndex: 2,
                          position: "relative",
                          fontWeight: active ? "600" : "400",
                        }}
                      >
                        {menu}
                      </p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </li>
          <div
            style={{
              position: "absolute",
              bottom: 10,
              textAlign: "center",
              right: 0,
              left: 0,
            }}
          >
            <p style={{ fontSize: "0.8em" }}>© Yoov 2021</p>
            <p className={"text-muted"} style={{ fontSize: "0.6em" }}>
              Dashboard for photographers
            </p>
          </div>
        </ul>
      </>
    );
  }
}
