import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

// reactstrap components
import { Button, Row, Col } from "reactstrap";

class Shooting extends React.Component {
  render() {
    return (
      <Row className="justify-content-center" style={{}}>
        <Col lg="4" md="8" sm="12">
          <h5 style={{ marginBottom: "20px", fontWeight: 600 }}>
            It’s time to shoot !
          </h5>
          <p className="mb-3 mt--3 text-muted">
            Make sure to listen to the client, and prioritize quality over
            quantity
          </p>
          <Button
            color="default"
            size="lg"
            style={{ width: "100%" }}
            onClick={() => this.props.callback()}
          >
            Next
          </Button>
        </Col>
      </Row>
    );
  }
}

Shooting.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Shooting);
